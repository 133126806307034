/*
====================================
[ CSS TABLE CONTENT ]
------------------------------------
    1.0 - General
    2.0 - Typography
    3.0 - Global Style
    4.0 - Navigation
    5.0 - Search Box
    6.0 - Page Title Section
    7.0 - Slider Section
    8.0 - Creative Section
    9.0 - Recent Project Section
    9.1 - Overlay and hover effect
    10.0 - Magnific Popup CSS
    11.0 - Feature Section
    12.0 - About Section
    13.0 - Counter Section
    14.0 - Team Section
    15.0 - Video Section
    16.0 - Service Section
    17.0 - Process Section
    18.0 - Portfolio Section
    19.0 - Portfolio Single Page
    20.0 - Partner Section
    21.0 - Case study section
    22.0 - Map Section
    23.0 - Contact Section
    24.0 - Footer Section
    25.0 - Preloader
    26.0 - Homepage Two
    27.0 - Homepage Three
    28.0 - About us Page
    29.0 - About us page two
    30.0 - Blog Page
    31.0 - Blog Page Version Two
    32.0 - Full Width Section
    33.0 - Call to action section
    34.0 - Client Testimonial Style
    35.0 - Parallax Homepage
    36.0 - Contact Page
    37.0 - 404 page
    38.0 - Slick slider customization
-------------------------------------
[ END CSS TABLE CONTENT ]
=====================================
*/

/* Theme Reset Start */


@import url("../css/custom-icons.min.css");
@import url("../css/icofonts.min.css");


/* Transitons */

@-webkit-keyframes it-zoom {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: .6; }
  100% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0; }
  }

@keyframes it-zoom {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: .6; }
  100% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0; }
  }




/* Selection */

::selection {background-color: #212121;color: #fff;}

/* All */

* {margin: 0;padding: 0;min-height:0;min-width:0;}
*, :after, :before {box-sizing: border-box;}

html, body{position: relative;height: 100%;}
body {color: #888;background: #fafafa;font-size:14px;font-family: 'Open Sans', sans-serif;font-weight: 400;}
iframe {border: 0;}
video {background-size: cover;display: table-cell;vertical-align: middle;width: 100%;}
.html5-video {background-color: #000; height: auto; left: 50%; min-height: 100%; min-width: 100%; position: absolute; top: 50%; -ms-transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%);  width: auto; z-index: -3; }
iframe, embed, object {max-width: 100%;}
.wrapper {position: relative;z-index: 20;overflow-x: hidden;}

/* Typography */

h1, h2, h3, h4, h5, h6 {color: #212121;margin: 20px 0 10px 0;font-style: normal;font-family: 'Montserrat', sans-serif;}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {line-height: 1.2em;}
h1 { font-size: 56px;}
h2 { font-size: 36px;}
h3 { font-size: 24px;}
h4 { font-size: 18px;}
h5 { font-size: 16px;}
h6 { font-size: 12px;}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { color: #212121;}
h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5:hover a, h6 a:hover { color: #4facfe;}
p a {}
p a:hover {color: #4facfe;}
p {letter-spacing: normal;line-height: 24px;color: #888;font-weight:400;font-family: 'Open Sans', sans-serif;}
a {text-decoration: none;color:#212121;}
a:hover, a:focus, a:active {outline: none;text-decoration: none;color:#4facfe;}
ul {list-style: disc;padding: 0;}
li > ul, li > ol {padding: 0;margin-left: 15px;}
figure {margin-bottom: 6px;position: relative;}
img {height: auto;max-width: 100%;}
.mark, mark{background-color:#4facfe;color:#fff;}
pre {margin: 0 20px;padding: 10px;border: 1px solid #e2e2e2;background: #f1f1f1;border-radius:0;}
blockquote {border-left: 2px solid #4facfe;background: #fff;padding: 15px 40px;}
blockquote p {font-size: 18px;line-height: 30px;margin-bottom: 17px !important;font-weight: 300;}
.blockquote-reverse, blockquote.pull-right {border-right: 2px solid #4facfe;background:#fff;}
.outline-alert {background-color: transparent!important;}
strong { color: #414141; }
hr{border:0;height: 1px;width:100%;margin:20px auto;}
hr.left-line{margin:20px 0 20px 0;width:80px;}
hr.center_line{width:80px;}
.text-decoration-underline{text-decoration: underline;}
.text-decoration-none{text-decoration: none;}
.line-trough{text-decoration: line-through;}
.list-inline>li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.section-title{margin-bottom: 30px;}
.section-title h1{font-size: 50px;font-weight: 500;margin-top: 10px;}
.section-title h2{font-size: 25px;line-height: 0.75;margin: 0;}


/* Forms */

label {margin-bottom: 10px;font-weight: 500;color:#212121;font-family: 'Open Sans', sans-serif;font-size: 14px;}
textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
border-color: none;box-shadow: none;outline: 0 none;}
.search-submit{position: absolute;right: 20px;top:14px;background: none;border:0;padding: 0;font-size:20px;-webkit-transition: all 0.3s;-o-transition: all 0.3s;transition: all 0.3s;color:#e0e0e0;}
.search-submit:hover{color: #4facfe;}
/* .select-custom {width: 100%;overflow: hidden;background: url(../images/select-arrow.png) no-repeat 97% 50%;border: 1px solid #fff;margin-bottom: 20px;} */
.select-custom select {width: 100%;border: none;box-shadow: none;background-color: transparent;background-image: none;-webkit-appearance: none;-moz-appearance: none;
appearance: none;cursor: pointer;}
.md-input, .md-textarea, .md-select select {padding: 14px 20px;font-size: 14px;line-height: normal;border-radius: 0;}
input, textarea, select {border: 1px solid #f5f7fa;font-size: 14px;padding: 8px 15px;width: 100%;margin: 0 0 10px 0;max-width: 100%;resize: none;background-color: transparent;color:#fff;}
.has-error input, .has-error textarea, .has-error select{border-color: #fe4f4f;}
.help-block{margin-top: 0;}
.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
color: #fe4f4f;font-size: 12px;}
.contact-form-style-01 input, .contact-form-style-01 textarea, .contact-form-style-01 select {color:#212121;}
.contact-form-style-02 .form-control{background-color: transparent;border-top:0;border-left:0;border-right:0;border-bottom:1px solid #fff;padding: 10px 0;}
.contact-form-style-02 .has-error input, .contact-form-style-02 .has-error textarea, .contact-form-style-02 .has-error select{border-color: #212121;}
.contact-form-style-02 .has-error .help-block, .contact-form-style-02 .has-error .control-label, .contact-form-style-02 .has-error .radio, .contact-form-style-02 .has-error .checkbox, .contact-form-style-02 .has-error .radio-inline, .contact-form-style-02 .has-error .checkbox-inline, .contact-form-style-02 .has-error.radio label, .contact-form-style-02 .has-error.checkbox label, .contact-form-style-02 .has-error.radio-inline label, .contact-form-style-02 .has-error.checkbox-inline label {
 color: #fff;}
 .contact-form-style-03 input, .contact-form-style-03 textarea, .contact-form-style-03 select {color:#212121;}

/* Sections */

section {padding-top:20px;padding-bottom:20px;position: relative;z-index: 10;background:#f5f5f5;overflow: hidden;}
section.lg-section{padding-top:150px;padding-bottom:150px;}
.section-heading {text-align: center;margin: auto;float: none;padding-bottom: 20px;}

/* Top Bar */

.top-bar{font-size: 12px;width: 100%;}
.dropdown-style-01 .dropdown-menu {margin-top: 12px;min-width: 130px;border-radius: 0;border: none;z-index: 444;right: 0;left: auto;}
.dropdown-style-01 .btn{color: #fff;font-size: 12px;padding: 0;margin: 0;background-color: transparent;font-weight: normal;text-transform: capitalize;border: 0;}

/* Navigations */

.navbar {
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px 0;
  margin: 0;
  width: 100%;
  background: transparent;
  border-bottom: 2px solid transparent;
  z-index: 999;
}

.navbar.nav-crev {
  width: 100%;
  left: 0px;
  top: 0px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-height: auto;
  -webkit-transform: translate(0) !important;
  -ms-transform: translate(0) !important;
  transform: translate(0) !important;
}

.navbar.nav-crev.nav-scroll {
  background: #fff;
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 15px 0;
  position: fixed;
  top: -80px;
  -webkit-transform: translateY(80px) !important;
  -ms-transform: translateY(80px) !important;
  transform: translateY(80px) !important;
  left: 0;
  width: 100%;
  border-radius: 0;
}

.navbar.nav-scroll {
  background: #fff;
  border-color: rgba(255, 255, 255, 0.15);
  padding: 0;
  position: fixed;
  top: -80px;
  -webkit-transition: -webkit-transform .8s;
  transition: -webkit-transform .8s;
  -o-transition: transform .8s;
  transition: transform .8s;
  transition: transform .8s, -webkit-transform .8s;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
  -webkit-box-shadow: 0px 10px 30px rgb(0 0 0 / 5%);
  box-shadow: 0px 10px 30px rgb(0 0 0 / 5%);
}

.navbar .icon-bar {
  color: #000;
}

.navbar.nav-scroll .icon-bar {
  color: #000;
}

.navbar .navbar-nav .nav-link {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin: 0 15px;
  font-family: 'Open Sans', sans-serif;
}

.navbar .navbar-nav .nav-link:hover, .navbar .navbar-nav .nav-link:focus{
  cursor: pointer;
}

.navbar.nav-scroll .navbar-nav .nav-link{color: #212121;}

.logo-wrap{display: flex;justify-content: center;align-items: center;}
.logo {
  max-width: 100%;
  height: auto;
}

.icon-img-100 img{width: 10%;}

.navbar.nav-dark {
  border-bottom: solid 1px #f1f1f1;
}

.navbar.nav-dark .navbar-nav .nav-link {
  color: #212121;
}

/* Menu Overlay */

body.overflow{overflow: hidden;}
.menu-overlay {position: fixed;top: 0;z-index: 998;display: none;overflow: hidden;width: 100%;height: 100%;background: #060606;left: 0;right: 0;}
.menu-overlay .menu-wrap {width: 100%;display: table;height: 100vh;}
ul.vcard-menu {margin: 0 auto;padding: 0;width: 100%;}
ul.vcard-menu li {padding: 10px 0;list-style: none;text-align: center;font-family: 'Montserrat', sans-serif;font-weight: 500;font-size: 50px;}
ul.vcard-menu li a {color: #fff;}
ul.vcard-menu li a:hover {color: #4facfe;}
.vcard-address{margin-top: 40px;margin-bottom: 40px;}

/* Sliders Text */
.slider-bg{
  border: 0;
  margin: 0;
  height: 90vh;
  background-color: #222225;
}
.slider-bg .slides, .slider-bg .slides>li {height: 100%;}
.slider-bg ul.slides {list-style: none;padding: 0;}
.slide-img {
  position: relative;
  height: 90vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-bg .hero-text {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: 100%;
  z-index: 10;
  color: #fff
}

.slider-bg .hero-text P {
  line-height: 50px;
  font-size: 35px
}

.slider-bg .hero-text h1 {
  font-size: 56px;
  font-weight: 100;
}

.slides .owl-theme .owl-nav {
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 100%
}

.slides .owl-theme .owl-nav [class*=owl-] {
  width: 56px;
  height: 56px;
  color: #fff;
  text-shadow: none;
  background-color: #212121;
  border: 1px solid #212121;
  text-decoration: none;
  display: block;
  line-height: 45px;
  font-size: 26px;
  position: absolute;
  z-index: 99;
  border-radius: 0
}

.slides .owl-theme .owl-nav .owl-prev:hover,.slides .owl-theme .owl-nav .owl-next:hover {
  border: 1px solid #fff;
  background-color: transparent
}

.slides .owl-theme .owl-nav .owl-prev {
  left: 10px
}

.slides .owl-theme .owl-nav .owl-next {
  right: 10px
}

.slides .owl-theme .owl-dots {
  width: 100%;
  position: absolute;
  z-index: 99;
  bottom: 20px
}

.slides .owl-theme .owl-dots .owl-dot span {
  width: 60px;
  height: 6px;
  border-radius: 0;
  background: #fff3
}

.slides .owl-theme .owl-dots .owl-dot span:hover,.slides .owl-theme .owl-dots .owl-dot.active span {
  background: #fff
}

.hero-text{display: table-cell;vertical-align: middle;position: relative;width: 100%;z-index: 10;color: #fff;}
.hero-text h3, .hero-text h1{color:#4facfe;}
.hero-text h1 {font-size:78px;letter-spacing:-2px;line-height: 1.25;}
.hero-text h1.letter-spacing-10{letter-spacing:10px;}
.hero-text h1.letter-spacing-0{letter-spacing:0px;}
.hero-text-wrap .hero-text h1.font-90px {font-size:90px;}
.hero-text h2 {font-size:62px;}
.hero-text h3 {font-size:49px;}
.hero-text h4 {font-size:30px;}
.hero-text h5 {font-size:19px;}
.hero-text h6 {font-size:14px;}
.hero-text-wrap.color-overlay-bg {background: rgba(191, 7, 49, 0.75);width: 100%;height: 101vh;position: absolute;z-index: 1;}
section.dark-block .hero-text-wrap, section.dark-block.title-hero-bg::before {background-color:transparent;}
.hero-text-wrap.gradient-overlay-bg {width: 100%;height: 100vh;position: absolute;z-index: 1;
background: rgba(79,172,254,1);
background: -moz-linear-gradient(45deg, rgba(79,172,254,0.5) 0%, rgba(0,242,254,0.5) 100%);
background: -webkit-linear-gradient(45deg, rgba(79,172,254,0.5) 0%, rgba(0,242,254,0.5) 100%);
background: -o-linear-gradient(45deg, rgba(79,172,254,0.5) 0%, rgba(0,242,254,0.5) 100%);
background: -ms-linear-gradient(45deg, rgba(79,172,254,0.5) 0%, rgba(0,242,254,0.5) 100%);
background: linear-gradient(45deg, rgba(79,172,254,0.5) 0%, rgba(0,242,254,0.5) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#00f2fe', GradientType=1 );
}
.hero-text-wrap.gradient-overlay-bg-two {width: 100%;height: 100vh;position: absolute;z-index: 1;
background: rgba(246,211,101,1);
background: -moz-linear-gradient(45deg, rgba(246,211,101,0.5) 0%, rgba(253,160,133,0.5) 100%);
background: -webkit-linear-gradient(45deg, rgba(246,211,101,0.5) 0%, rgba(253,160,133,0.5) 100%);
background: -o-linear-gradient(45deg, rgba(79,172,254,0.5) 0%, rgba(253,242,254,0.5) 100%);
background: -ms-linear-gradient(45deg, rgba(246,211,101,0.5) 0%, rgba(253,160,133,0.5) 100%);
background: linear-gradient(45deg, rgba(246,211,101,0.5) 0%, rgba(253,160,133,0.5) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#00f2fe', GradientType=1 );
}
.rev_slider a {-webkit-transition: all .3s ease-in !important;-o-transition: all .3s ease-in !important;transition: all .3s ease-in !important;}
.gradient-overlay-bg {
background: rgba(79,172,254,1);
background: -moz-linear-gradient(45deg, rgba(79,172,254,0.5) 0%, rgba(0,242,254,0.5) 100%);
background: -webkit-linear-gradient(45deg, rgba(79,172,254,0.5) 0%, rgba(0,242,254,0.5) 100%);
background: -o-linear-gradient(45deg, rgba(79,172,254,0.5) 0%, rgba(0,242,254,0.5) 100%);
background: -ms-linear-gradient(45deg, rgba(79,172,254,0.5) 0%, rgba(0,242,254,0.5) 100%);
background: linear-gradient(45deg, rgba(79,172,254,0.5) 0%, rgba(0,242,254,0.5) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#00f2fe', GradientType=1 );
width: 100%;height: 100%;position: absolute;z-index: -1;top:0;left:0;
}
.gradient-overlay-bg-two {
background: rgba(236,119,171);
background: -moz-linear-gradient(45deg, rgba(236,119,171,0.5) 0%, rgba(120,115,245,0.5) 100%);
background: -webkit-linear-gradient(45deg, rgba(236,119,171,0.5) 0%, rgba(120,115,245,0.5) 100%);
background: -o-linear-gradient(45deg, rgba(236,119,171,0.5) 0%, rgba(120,115,245,0.5) 100%);
background: -ms-linear-gradient(45deg, rgba(236,119,171,0.5) 0%, rgba(120,115,245,0.5) 100%);
background: linear-gradient(45deg, rgba(236,119,171,0.5) 0%, rgba(120,115,245,0.5) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec77ab', endColorstr='#734b6d', GradientType=1 );
width: 100%;height: 100%;position: absolute;z-index: -1;top:0;left:0;
}
.gradient-overlay-bg-three {
background: rgba(202,73,118);
background: -moz-linear-gradient(45deg, rgba(202,73,118,0.5) 0%, rgba(255,84,84,0.5) 100%);
background: -webkit-linear-gradient(45deg, rgba(202,73,118,0.5) 0%, rgba(255,84,84,0.5) 100%);
background: -o-linear-gradient(45deg, rgba(202,73,118,0.5) 0%, rgba(255,84,84,0.5) 100%);
background: -ms-linear-gradient(45deg, rgba(202,73,118,0.5) 0%, rgba(255,84,84,0.5) 100%);
background: linear-gradient(45deg, rgba(202,73,118,0.5) 0%, rgba(255,84,84,0.5) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ca4976', endColorstr='#7873f5', GradientType=1 );
width: 100%;height: 100%;position: absolute;z-index: -1;top:0;left:0;
}
.gradient-overlay-bg-four {
background: rgba(206,159,252);
background: -moz-linear-gradient(45deg, rgba(206,159,252,0.5) 0%, rgba(115,103,240,0.5) 100%);
background: -webkit-linear-gradient(45deg, rgba(206,159,252,0.5) 0%, rgba(115,103,240,0.5) 100%);
background: -o-linear-gradient(45deg, rgba(206,159,252,0.5) 0%, rgba(115,103,240,0.5) 100%);
background: -ms-linear-gradient(45deg, rgba(206,159,252,0.5) 0%, rgba(115,103,240,0.5) 100%);
background: linear-gradient(45deg, rgba(206,159,252,0.5) 0%, rgba(115,103,240,0.5) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ce9ffc', endColorstr='#7367f0', GradientType=1 );
width: 100%;height: 100%;position: absolute;z-index: -1;top:0;left:0;
}
.gradient-overlay-bg-five {
background: rgba(100,43,115,1);
background: -moz-linear-gradient(45deg, rgba(100,43,115,0.5) 0%, rgba(198,66,110,0.5) 100%);
background: -webkit-linear-gradient(45deg, rgba(100,43,115,0.5) 0%, rgba(198,66,110,0.5) 100%);
background: -o-linear-gradient(45deg, rgba(100,43,115,0.5) 0%, rgba(198,66,110,0.5) 100%);
background: -ms-linear-gradient(45deg, rgba(100,43,115,0.5) 0%, rgba(198,66,110,0.5) 100%);
background: linear-gradient(45deg, rgba(100,43,115,0.5) 0%, rgba(198,66,110,0.5) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#642b73', endColorstr='#c6426e', GradientType=1 );
width: 100%;height: 100%;position: absolute;z-index: -1;top:0;left:0;
}
.hero-banner-text-1 h1{font-size: 120px;}
.hero-banner-text-1 h2{font-size: 70px;}
.hb-gradient-bg .hb-shapes .shape-1{position: absolute;top: 15%;left: 2%;transition: transform 0.5s ease;}
.hb-gradient-bg .hb-shapes .shape-2{position: absolute;top: 55%;left: 70%;transition: transform 0.5s ease;}
.hb-gradient-bg .hb-shapes .shape-3{position: absolute;top: 35%;left: 45%;transition: transform 0.5s ease;opacity: 0.9;}
.hb-gradient-bg .hb-shapes .shape-4{position: absolute;top: 45%;left: -7%;transition: transform 0.5s ease;}
.hb-gradient-bg .hb-shapes .shape-5{position: absolute;top: -10%;left: 50%;transition: transform 0.5s ease;}

/* Helper Classes */

::-webkit-input-placeholder{color:#212121!important;}
::-moz-placeholder{color:#212121!important;}
:-ms-input-placeholder{color:#212121!important;}
:-moz-placeholder{color:#212121!important;}

.style-02::-webkit-input-placeholder{color:#fff!important;}
.style-02::-moz-placeholder{color:#fff!important;}
.style-02:-ms-input-placeholder{color:#fff!important;}
.style-02:-moz-placeholder{color:#fff!important;}

input.search-bar-top::-webkit-input-placeholder { color: #fff!important; }
input.search-bar-top::-moz-placeholder { color: #fff!important; }
input.search-bar-top:-ms-input-placeholder { color: #fff!important; }
input.search-bar-top:-moz-placeholder { color: #fff!important; }

/* Positions */

.fixed{position:fixed!important;}
.relative{position:relative!important;}
.absolute{position:absolute!important;}
.static{position:static!important;}

/* Z-index */

.z-index-1 {z-index:1;}
.z-index-2 {z-index:2;}
.z-index-3 {z-index:3;}
.z-index-4 {z-index:4;}
.z-index-5 {z-index:5;}
.z-index-0 {z-index:0;}
.z-index-minus2 {z-index:-2;}

/* Displays */
.display-none{display: none!important;}
.display-block{display: block!important;}
.display-inline-block{display: inline-block!important;}
.display-table{display: table;}
.display-table-cell{display: table-cell!important;}
.float-none{float:none;}
.centerize-col{float: none!important;margin-left: auto;margin-right: auto;}
.center-layout {display: table;table-layout: fixed;height: 100%;width: 100%;position: relative;}
.v-align-middle {display: table-cell;vertical-align: middle;}

/* Width Pixels */

.width-1px {width: 1px}
.width-2px {width: 2px}
.width-3px {width: 3px}
.width-4px {width: 4px}
.width-5px {width: 5px}
.width-6px {width: 6px}
.width-7px {width: 7px}
.width-8px {width: 8px}
.width-9px {width: 9px}
.width-10px {width: 10px}
.width-80px {width: 80px}
.width-90px {width: 90px}
.width-100px {width: 100px}
.width-150px {width: 150px}
.width-200px {width: 200px}
.width-250px {width: 250px}
.width-300px {width: 300px}
.width-350px {width: 350px}
.width-400px {width: 400px}
.width-450px {width: 450px}
.width-500px {width: 500px!important}
.width-550px {width: 550px}
.width-600px {width: 600px}
.width-650px {width: 650px!important}
.width-700px {width: 700px}
.width-800px {width: 800px}

/* Width Percentage */

.width-10-percent {width: 10% !important}
.width-20-percent {width: 20% !important}
.width-30-percent {width: 30% !important}
.width-40-percent {width: 40% !important}
.width-50-percent {width: 50% !important}
.width-60-percent {width: 60% !important}
.width-70-percent {width: 70% !important}
.width-80-percent {width: 80% !important}
.width-90-percent {width: 90% !important}
.width-100-percent {width: 100% !important}
.width-auto {width:auto !important}
.max-width-100 {max-width: 100%}

/* Minimum Width */

.min-width-100px {min-height: 100px}
.min-width-200px {min-height: 200px}
.min-width-300px {min-height: 300px}
.min-width-400px {min-height: 400px}
.min-width-500px {min-height: 500px}
.min-width-600px {min-height: 600px}
.min-width-700px {min-height: 700px}

/* View Width */

.view-width-100vh{width: 100vh;}

/* Height Pixels */

.height-1px {height: 1px!important}
.height-2px {height: 2px!important}
.height-3px {height: 3px!important}
.height-4px {height: 4px!important}
.height-5px {height: 5px!important}
.height-6px {height: 6px!important}
.height-7px {height: 7px!important}
.height-8px {height: 8px!important}
.height-9px {height: 9px!important}
.height-10px {height: 10px!important}
.height-80px {height: 80px}
.height-90px {height: 90px}
.height-100px {height: 100px}
.height-150px {height: 150px}
.height-200px {height: 200px}
.height-250px {height: 250px}
.height-300px {height: 300px}
.height-350px {height: 350px}
.height-400px {height: 400px}
.height-450px {height: 450px}
.height-500px {height: 500px!important;}
.height-550px {height: 550px}
.height-600px {height: 600px}
.height-650px {height: 650px!important;}
.height-700px {height: 700px}
.height-800px {height: 800px}

/* Height Percentage */

.height-10-percent {height: 10%}
.height-20-percent {height: 20%}
.height-30-percent {height: 30%}
.height-40-percent {height: 40%}
.height-50-percent {height: 50%}
.height-60-percent {height: 60%}
.height-70-percent {height: 70%}
.height-80-percent {height: 80%}
.height-90-percent {height: 90%}
.height-100-percent {height: 100% !important}
.height-auto {height:auto !important}
.max-height-100 {max-height: 100%}

/* Minimum Heights */

.min-height-100px {min-height: 100px}
.min-height-200px {min-height: 200px}
.min-height-300px {min-height: 300px!important;}
.min-height-400px {min-height: 400px}
.min-height-500px {min-height: 500px}
.min-height-600px {min-height: 600px}
.min-height-700px {min-height: 700px}
.min-height-800px {min-height: 800px}
.min-height-900px {min-height: 900px}

/* Hero Heights  */

.xs-screen {height:300px;}
.sm-screen {height:400px;}
.md-screen {height:600px;}
.lg-screen {height:700px;}
.xl-screen {height:800px;}
.xxl-screen{height: 1000px;}

/* View Heights */

.view-height-100vh{height: 100vh!important;}


/* Backgrounds */

.bordered-bg {background-color: transparent;border-top: 1px solid #e0e0e0;border-bottom: 1px solid #e0e0e0;}
.transparent-bg{background-color:transparent!important;}
.dark-bg{background-color:#212121;}
.darken-bg{background-color:#151515;}
.d-dark-bg{background-color:#0a0a0a;}
.white-bg{background-color: #fff!important;}
.default-bg{background-color:#4facfe;}
.secondary-bg{background-color: #ec77ab;}
.dark-grey-bg{background-color: #e0e0e0;}
.grey-bg{background-color:#f5f7fa!important;}
.green-bg{background-color:#2cc99d!important;}

.hb-gradient-bg{background: rgba(30,13,99,1);
background: -moz-linear-gradient(180deg, rgba(30,13,99,1) 0%, rgba(15,1,38,1) 100%);
background: -webkit-linear-gradient(180deg, rgba(30,13,99,1) 0%, rgba(15,1,38,1) 100%);
background: -o-linear-gradient(180deg, rgba(30,13,99,1) 0%, rgba(15,1,38,1) 100%);
background: -ms-linear-gradient(180deg, rgba(30,13,99,1) 0%, rgba(15,1,38,1) 100%);
background: linear-gradient(180deg, rgba(30,13,99,1) 0%, rgba(15,1,38,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e0d63', endColorstr='#0f0126', GradientType=1 );}
.gradient-bg{background: rgba(79,172,254,1);
background: -moz-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -webkit-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -ms-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#00f2fe', GradientType=1 );}
.gradient-bg-two{background: rgba(236,119,171);
background: -moz-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -webkit-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -o-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -ms-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec77ab', endColorstr='#734b6d', GradientType=1 );}
.gradient-bg-three{background: rgba(202,73,118,1);
background: -moz-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: -webkit-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: -o-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: -ms-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ca4976', endColorstr='#ff5454', GradientType=1 );}
.gradient-bg-four{background: rgba(206,159,252,1);
background: -moz-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -webkit-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -o-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -ms-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CE9FFC', endColorstr='#7367F0', GradientType=1 );}
.gradient-bg-five{background: rgba(35,37,38,1);
background: -moz-linear-gradient(45deg, rgba(35,37,38,1) 0%, rgba(65,67,69,1) 100%);
background: -webkit-linear-gradient(45deg, rgba(35,37,38,1) 0%, rgba(65,67,69,1) 100%);
background: -o-linear-gradient(45deg, rgba(35,37,38,1) 0%, rgba(65,67,69,1) 100%);
background: -ms-linear-gradient(45deg, rgba(35,37,38,1) 0%, rgba(65,67,69,1) 100%);
background: linear-gradient(45deg, rgba(35,37,38,1) 0%, rgba(65,67,69,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#232526', endColorstr='#414345', GradientType=1 );}
.gradient-bg-six{
  background: #9357cc;
  background: -webkit-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);
  background: -moz-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);
  background: -o-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);
  background: -ms-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);
  background: linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);
}
.gradient-bg-seven{
  background: rgba(246,211,101,1);
  background: -moz-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
  background: -o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(253,242,254,1) 100%);
  background: -ms-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
  background: linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6d365', endColorstr='#fda085', GradientType=1 );
}
.gradient-bg-eight{
  background: rgba(100,43,115,1);
  background: -moz-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
  background: -o-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
  background: -ms-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
  background: linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#C6426E', endColorstr='#642B73', GradientType=1 );
}
.gradient-bg-nine{
  background: rgba(247,157,0,1);
  background: -moz-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(10,243,140,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
  background: -o-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
  background: -ms-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
  background: linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f79d00', endColorstr='#64f38c', GradientType=1 );
}
.gradient-bg-ten{
  background: rgba(61,82,82,1);
  background: -moz-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
  background: -o-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
  background: -ms-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
  background: linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e5151', endColorstr='#decba4', GradientType=1 );
}
.bordered-bg.white-bg{background-color: #fff;}
/* .pattern-bg-dark{background-image: url(../images/background/pattern-bg-dark.jpg);background-repeat: repeat;}
.pattern-bg-light{background-image: url(../images/light-pattern.png);background-repeat: repeat;}
.pattern-bg-colored{background-image: url(../images/colored-pattern.png);background-repeat: repeat;} */
.light-br-r{border-right:1px solid #f5f7fa;}
.fixed-bg{background-attachment: fixed!important;}
.overlay-bg {background: rgba(0,0,0,0.6);width: 100%;height: 100%;position: absolute;z-index: -1;top:0;left:0;}
.front-overlay-bg {background: rgba(0,0,0,0.6);width: 100%;height: 100%;position: absolute;z-index: 99;top:0;left:0;}
.color-overlay-bg {background: rgba(191, 7, 49, 0.75);width: 100%;height: 100%;position: absolute;z-index: -1;top: 0;left: 0;right: 0;}
.front-color-overlay-bg{background: rgba(191, 7, 49, 0.75);width: 100%;height: 100%;position: absolute;z-index: 99;top: 0;left: 0;right: 0;}
.secondary-color-overlay-bg {background: rgba(67, 13, 60, 0.75);width: 100%;height: 100%;position: absolute;z-index: -1;top: 0;left: 0;right: 0;}
.parallax-overlay {background: rgba(0, 0, 0, 0.6);width: 100%;height: 100%;position: absolute;z-index: -1;top:0;left:0;}
.parallax-overlay-white {background: rgba(255, 255, 255, 0.6);width: 100%;height: 100%;position: absolute;z-index: -1;top:0;left:0;}
.parallax-bg {background-size: cover;-webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;background-repeat:no-repeat;background-position: 0% 0%;}


/* Horizontal Line */

.line-horizontal{width: 100%;height: 1px;}

/* Box Shadows */

.box-shadow {box-shadow: 0 10px 30px 5px rgba(17,21,23,.1);}
.box-shadow-hover:hover{box-shadow: 0 10px 30px 5px rgba(17,21,23,.1);}

/* Fonts */

.montserrat-font{font-family: 'Montserrat', sans-serif;}
.open-font{font-family: 'Open Sans', sans-serif;}
.raleway-font{font-family: 'Raleway', sans-serif;}

.font-100 {font-weight:100;}
.font-200 {font-weight:200;}
.font-300 {font-weight:300;}
.font-400 {font-weight:400;}
.font-500 {font-weight:500;}
.font-600 {font-weight:600;}
.font-700 {font-weight:700!important;}
.font-900 {font-weight:900;}

.font-12px {font-size: 12px;}
.font-14px {font-size: 14px;}
.font-16px {font-size: 16px!important;}
.font-18px {font-size: 18px;}
.font-20px {font-size: 20px!important;}
.font-26px {font-size: 26px;}
.font-30px {font-size: 30px;}
.font-35px {font-size: 35px;}
.font-40px {font-size: 40px;}
.font-50px {font-size: 50px;}
.font-60px {font-size: 60px!important;}
.font-70px {font-size: 70px;}
.font-80px {font-size: 80px;}
.font-90px {font-size: 90px;}
.font-100px {font-size: 100px!important;}
.font-120px {font-size: 120px;}
.font-130px {font-size: 130px;}

.font-italic {font-style: italic;}
.font-bold {font-weight:bold;}

/* Fonts Colors */

.default-color {color: #4facfe!important;}
.secondary-color {color: #ec77ab!important;}
.white-color {color: #fff;}
.white-color p, .white-color h1, .white-color h2, .white-color h3, .white-color h4, .white-color h5, .white-color h6, .white-color i{color: #fff!important;}
.white-smoke {color: #f9f9f9!important;}
.white-smoke p, .white-smoke h1, .white-smoke h2, .white-smoke h3, .white-smoke h4, .white-smoke h5, .white-smoke h6 {color: #f9f9f9!important;}
.dark-color {color: #212121!important;}
.dark-color p, .dark-color h1, .dark-color h2, .dark-color h3, .dark-color h4, .dark-color h5, .dark-color h6 {color: #212121!important;}
.grey-color {color: #aaa !important;}
.grey-color p, .grey-color h1, .grey-color h2, .grey-color h3, .grey-color h4, .grey-color h5, .grey-color h6 {color: #aaa!important;}
.red-color{color:#ca4976;}
.purple-color{color:#642B73;}
.green-color{color:#2cc99d;}
.yellow-color{color:#f6d365;}
.orange-color{color:#f79d00;}
.gradient-color{background: -webkit-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;}

/* Line Heights */

.line-height-15 {line-height: 15px;}
.line-height-20 {line-height: 20px;}
.line-height-22 {line-height: 22px;}
.line-height-24 {line-height: 24px;}
.line-height-25 {line-height: 25px;}
.line-height-26 {line-height: 26px;}
.line-height-30 {line-height: 30px;}
.line-height-35 {line-height: 35px;}
.line-height-40 {line-height: 40px;}
.line-height-45 {line-height: 45px;}
.line-height-50 {line-height: 50px;}
.line-height-60 {line-height: 60px;}
.line-height-70 {line-height: 70px;}
.line-height-80 {line-height: 80px;}
.line-height-90 {line-height: 90px;}
.line-height-100 {line-height: 100px;}
.line-height-110 {line-height: 110px;}
.line-height-120 {line-height: 120px;}

/* Letter Spacings */

.letter-spacing-0 {letter-spacing: 0px;}
.letter-spacing-1 {letter-spacing: 1px;}
.letter-spacing-2 {letter-spacing: 2px;}
.letter-spacing-3 {letter-spacing: 3px;}
.letter-spacing-4 {letter-spacing: 4px;}
.letter-spacing-5 {letter-spacing: 5px;}
.letter-spacing-6 {letter-spacing: 6px;}
.letter-spacing-7 {letter-spacing: 7px;}
.letter-spacing-8 {letter-spacing: 8px;}
.letter-spacing-9 {letter-spacing: 9px;}
.letter-spacing-10 {letter-spacing: 10px;}

/* Margins */

.margin-left-auto{margin-left: auto;}
.margin-right-auto{margin-right: auto;}
.remove-margin {margin: 0!important;}

.all-margin-10{margin:10px;}
.all-margin-20{margin:20px;}
.all-margin-30{margin:30px;}
.all-margin-40{margin:40px;}
.all-margin-50{margin:50px;}
.all-margin-60{margin:60px;}
.all-margin-70{margin:70px;}
.all-margin-80{margin:80px;}
.all-margin-90{margin:90px;}
.all-margin-100{margin:100px;}
.all-margin-110{margin:110px;}
.all-margin-120{margin:120px;}

.mt-minus-10 {margin-top: -10px;}
.mt-minus-20 {margin-top: -20px;}
.mt-minus-30 {margin-top: -30px;}
.mt-minus-40 {margin-top: -40px;}
.mt-minus-50 {margin-top: -50px;}
.mt-minus-60 {margin-top: -60px;}
.mt-minus-70 {margin-top: -70px;}
.mt-minus-80 {margin-top: -80px;}
.mt-minus-90 {margin-top: -90px;}
.mt-minus-100 {margin-top: -100px;}
.mt-minus-110 {margin-top: -110px;}
.mt-minus-120 {margin-top: -120px;}

.mt-0 {margin-top: 0px;}
.mt-10 {margin-top: 10px;}
.mt-20 {margin-top: 20px!important;}
.mt-30 {margin-top: 30px;}
.mt-40 {margin-top: 40px;}
.mt-50 {margin-top: 50px;}
.mt-60 {margin-top: 60px;}
.mt-70 {margin-top: 70px;}
.mt-80 {margin-top: 80px;}
.mt-90 {margin-top: 90px;}
.mt-100 {margin-top: 100px;}
.mt-110 {margin-top: 110px;}
.mt-120 {margin-top: 120px;}

.mb-0 {margin-bottom: 0px;}
.mb-10 {margin-bottom: 10px;}
.mb-20 {margin-bottom: 20px;}
.mb-30 {margin-bottom: 30px;}
.mb-40 {margin-bottom: 40px;}
.mb-50 {margin-bottom: 50px;}
.mb-60 {margin-bottom: 60px;}
.mb-70 {margin-bottom: 70px;}
.mb-80 {margin-bottom: 80px;}
.mb-90 {margin-bottom: 90px;}
.mb-100 {margin-bottom: 100px;}
.mb-110 {margin-bottom: 110px;}
.mb-120 {margin-bottom: 120px;}

.mr-0 {margin-right: 0px!important;}
.mr-5 {margin-right: 5px;}
.mr-10 {margin-right: 10px;}
.mr-20 {margin-right: 20px;}
.mr-30 {margin-right: 30px;}
.mr-40 {margin-right: 40px;}
.mr-50 {margin-right: 50px;}
.mr-60 {margin-right: 60px;}
.mr-70 {margin-right: 70px;}
.mr-80 {margin-right: 80px;}
.mr-90{margin-right: 90px;}
.mr-100{margin-right: 100px;}
.mr-110{margin-right: 110px;}
.mr-120{margin-right: 120px;}

.ml-0 {margin-left: 0px!important;}
.ml-5 {margin-left: 5px;}
.ml-10 {margin-left: 10px;}
.ml-20 {margin-left: 20px;}
.ml-30 {margin-left: 30px;}
.ml-40 {margin-left: 40px;}
.ml-50 {margin-left: 50px;}
.ml-60 {margin-left: 60px;}
.ml-70 {margin-left: 70px;}
.ml-80 {margin-left: 80px;}
.ml-90{margin-left: 90px;}
.ml-100{margin-left: 100px;}
.ml-110{margin-left: 110px;}
.ml-120{margin-left: 120px;}

/* Paddings */

.remove-padding {padding: 0!important;}
.all-padding-10{padding: 10px;}
.all-padding-20{padding: 20px;}
.all-padding-30{padding: 30px;}
.all-padding-40{padding: 40px;}
.all-padding-50{padding: 50px;}
.all-padding-60{padding: 60px;}
.all-padding-70{padding: 70px;}
.all-padding-80{padding: 80px;}
.all-padding-90{padding: 90px;}
.all-padding-100{padding: 100px;}
.all-padding-110{padding: 110px;}
.all-padding-120{padding: 120px;}

.pt-0{padding-top:0;}
.pt-10{padding-top:10px;}
.pt-20{padding-top:20px;}
.pt-30{padding-top:30px;}
.pt-40{padding-top:40px;}
.pt-50{padding-top:50px;}
.pt-60{padding-top:60px;}
.pt-70{padding-top:70px;}
.pt-80{padding-top:80px;}
.pt-90{padding-top:90px;}
.pt-100{padding-top:100px;}
.pt-110{padding-top:110px;}
.pt-120{padding-top:120px;}

.pb-0 {padding-bottom: 0;}
.pb-10 {padding-bottom: 10px;}
.pb-20 {padding-bottom: 20px;}
.pb-30 {padding-bottom: 30px;}
.pb-40 {padding-bottom: 40px;}
.pb-50 {padding-bottom: 50px;}
.pb-60 {padding-bottom: 60px;}
.pb-70 {padding-bottom: 70px;}
.pb-80 {padding-bottom: 80px;}
.pb-90 {padding-bottom: 90px;}
.pb-100 {padding-bottom: 100px;}
.pb-110 {padding-bottom: 110px;}
.pb-120 {padding-bottom: 120px;}

.pr-0 {padding-right: 0;}
.pr-10 {padding-right: 10px;}
.pr-20 {padding-right: 20px;}
.pr-30 {padding-right: 30px;}
.pr-40 {padding-right: 40px;}
.pr-50 {padding-right: 50px;}
.pr-60 {padding-right: 60px;}
.pr-70 {padding-right: 70px;}
.pr-80 {padding-right: 80px;}
.pr-90 {padding-right: 90px;}
.pr-100 {padding-right: 100px;}
.pr-110 {padding-right: 110px;}
.pr-120 {padding-right: 120px;}

.pl-0 {padding-left: 0;}
.pl-10 {padding-left: 10px;}
.pl-20 {padding-left: 20px;}
.pl-30 {padding-left: 30px;}
.pl-40 {padding-left: 40px;}
.pl-50 {padding-left: 50px;}
.pl-60 {padding-left: 60px;}
.pl-70 {padding-left: 70px;}
.pl-80 {padding-left: 80px;}
.pl-90 {padding-left: 90px;}
.pl-100 {padding-left: 100px;}
.pl-110 {padding-left: 110px;}
.pl-120 {padding-left: 120px;}

/* Position Spaces */

.top-0{top: 0;}
.top-10{top: 10px;}
.top-20{top: 20px!important;}
.top-30{top: 30px;}
.top-40{top: 40px;}
.top-50{top: 50px;}
.top-60{top: 60px;}
.top-70{top: 70px;}
.top-80{top: 80px;}
.top-90{top: 90px;}
.top-100{top: 100px;}
.top-110{top: 110px;}
.top-120{top: 120px;}

.bottom-0{bottom: 0;}
.bottom-10{bottom: 10px;}
.bottom-20{bottom: 20px;}
.bottom-30{bottom: 30px;}
.bottom-40{bottom: 40px;}
.bottom-50{bottom: 50px;}
.bottom-60{bottom: 60px;}
.bottom-70{bottom: 70px;}
.bottom-80{bottom: 80px;}
.bottom-90{bottom: 90px;}
.bottom-100{bottom: 100px;}
.bottom-110{bottom: 110px;}
.bottom-120{bottom: 120px;}

.right-0{right: 0;}
.right-10{right: 10px;}
.right-20{right: 20px;}
.right-30{right: 30px;}
.right-40{right: 40px;}
.right-50{right: 50px;}
.right-60{right: 60px;}
.right-70{right: 70px;}
.right-80{right: 80px;}
.right-90{right: 90px;}
.right-100{right: 100px;}
.right-110{right: 110px;}
.right-120{right: 120px;}

.left-0{left: 0;}
.left-10{left: 10px;}
.left-20{left: 20px;}
.left-30{left: 30px;}
.left-40{left: 40px;}
.left-50{left: 50px;}
.left-60{left: 60px;}
.left-70{left: 70px;}
.left-80{left: 80px;}
.left-90{left: 90px;}
.left-100{left: 100px;}
.left-110{left: 110px;}
.left-120{left: 120px;}

.top-minus-10{top:-10px;}
.top-minus-20{top:-20px;}
.top-minus-30{top:-30px;}
.top-minus-40{top:-40px;}
.top-minus-50{top:-50px;}
.top-minus-60{top:-60px;}
.top-minus-70{top:-70px;}
.top-minus-80{top:-80px;}
.top-minus-90{top:-90px;}
.top-minus-100{top:-100px;}
.top-minus-110{top:-110px;}

.bottom-minus-10{bottom:-10px;}
.bottom-minus-20{bottom:-20px;}
.bottom-minus-30{bottom:-30px;}
.bottom-minus-40{bottom:-40px;}
.bottom-minus-50{bottom:-50px;}
.bottom-minus-60{bottom:-60px;}
.bottom-minus-70{bottom:-70px;}
.bottom-minus-80{bottom:-80px;}
.bottom-minus-90{bottom:-90px;}
.bottom-minus-100{bottom:-100px;}
.bottom-minus-110{bottom:-110px;}

.right-minus-10{right:-10px;}
.right-minus-20{right:-20px;}
.right-minus-30{right:-30px;}
.right-minus-40{right:-40px;}
.right-minus-50{right:-50px;}
.right-minus-60{right:-60px;}
.right-minus-70{right:-70px;}
.right-minus-80{right:-80px;}
.right-minus-90{right:-90px;}
.right-minus-100{right:-100px;}
.right-minus-110{right:-110px;}

.left-minus-10{left:-10px;}
.left-minus-20{left:-20px;}
.left-minus-30{left:-30px;}
.left-minus-40{left:-40px;}
.left-minus-50{left:-50px;}
.left-minus-60{left:-60px;}
.left-minus-70{left:-70px;}
.left-minus-80{left:-80px;}
.left-minus-90{left:-90px;}
.left-minus-100{left:-100px;}
.left-minus-110{left:-110px;}


/* Lines */

.line-verticle-sm{width: 1px;height: 8px;}

/* Borders */

.bordered {border:1px solid #e0e0e0;}
.border-right{border-right:1px solid #e0e0e0;}
.border-left{border-left:1px solid #e0e0e0;}
.border-bottom{border-bottom:1px solid #e0e0e0;}
.border-radius-0{border-radius: 0!important;}
.border-radius-10{border-radius:10px!important;}
.border-radius-25 {border-radius:25px!important;}
.border-radius-50 {border-radius:50px!important;}
.border-radius-100 {border-radius:100px!important;}
.border-white-10{border:10px solid #fff;}
.border-white-15{border:15px solid #fff;}
.border-white-20{border:20px solid #fff;}

/* dropcap */

.first-letter {float: left; font-size: 50px; margin: 0 20px 0 0; text-align: center; padding: 10px 0; font-weight: 600}
.first-letter-big {float: left; font-size: 110px; line-height: 110px; margin: 0 20px 0 0; padding:0 8px;  text-align: center; font-weight: 600; position: relative;}
.first-letter-big:before {position: absolute; border-bottom: 1px solid; content: ""; display: block;  width: 100%; top: 55%; left: 0}
.first-letter-block {font-size: 35px;height: 55px;line-height: 25px;padding: 15px 0;width: 55px;font-weight: 700;margin-top: 5px;}
.first-letter-block-round { border-radius:6px; border:1px solid;}
.first-letter-round {border-radius: 50%; font-size:35px; padding: 15px; width: 65px; height: 65px; line-height: 32px;}

/* Images */
.img-responsive, .full-width{width:100%;}

/* Buttons */
.btn, .form-control, .form-control:hover, .form-control:focus, .navbar-custom .dropdown-menu {-webkit-box-shadow: none;box-shadow: none;}
button{transition-duration: 0.3s;transition-timing-function: ease-in-out;}
button:focus {outline: none !important;}
.btn {font-family: 'Montserrat', sans-serif;font-size: 11px;font-weight: 700;text-transform: uppercase;line-height: 1;
letter-spacing: 1px;padding: 8px 10px 7px 10px;border-color: transparent;transition-duration: 0.3s;transition-timing-function: ease-in-out;}
.btn:hover, .btn:focus, .btn.focus, .btn:active, .btn.active {border-color: transparent;}
.btn-default:active:hover,.btn-default.active:hover,.open>.dropdown-toggle.btn-default:hover,.btn-default:active:focus,.btn-default.active:focus,.open>.dropdown-toggle.btn-default:focus,.btn-default:active.focus,.btn-default.active.focus,.open>.dropdown-toggle.btn-default.focus{color: #fff;background-color: #4facfe;
border-color: #4facfe;outline: 0;}
.btn.btn-animated {transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);transform: translateY(0px);-webkit-transform: translateY(0px);-moz-transform: translateY(0px);
box-shadow: 0px 0px 51px 0px rgba(0, 0, 0, 0.08), 0px 6px 18px 0px rgba(0, 0, 0, 0.05);}
.btn.btn-animated:hover, .btn.btn-animated:focus, .btn.btn-animated.focus {transform: translateY(-5px);-webkit-transform: translateY(-5px);-moz-transform: translateY(-5px);box-shadow: 0px 0px 114px 0px rgba(0, 0, 0, 0.08), 0px 30px 25px 0px rgba(0, 0, 0, 0.05);}
.btn + .btn {margin-left: 10px;}
.btn i {margin-left: 5px;vertical-align: middle;position: relative;top: -1px;}
.btn:focus, .btn:focus:active {outline: none;}
.btn-circle {border-radius:100px;}
.btn-rounded {border-radius:10px;}
.btn-square {border-radius:0px;}
.btn:active, .btn.active {outline: 0;background-image: none;-webkit-box-shadow: none;box-shadow: none;}
.btn-mobilize .btn {margin: 0 10px;}
.btn.btn-xl {padding: 16px 44px;font-size: 14px;}
.btn.btn-lg {padding: 13px 40px;font-size: 12px;}
.btn.btn-md{padding: 12px 30px;font-size: 11px;}
.btn.btn-sm {padding: 11px 20px;font-size: 9px;}
.btn.btn-xs {padding: 8px 12px;font-size: 8px;}
.btn.btn-fullwidth {width:100%;min-width:300px;}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {opacity: 1;}
.btn-primary{color: #fff;background-color: #4facfe;border:2px solid #4facfe;}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus{color: #fff;border:2px solid #ec77ab;background-color: #ec77ab;}
.btn-primary-outline{color: #fff;border:2px solid transparent;background-color: #4facfe;}
.btn-primary-outline:hover, .btn-primary-outline:active, .btn-primary-outline:focus{color: #fff;border:2px solid #fff;background-color: transparent;}
.btn-color {color: #fff;background-color: #4facfe;}
.btn-color:hover, .btn-color:active, .btn-color:focus {background-color: #00f2fe;color: #fff;}
.btn-outline-color {color: #4facfe;border:2px solid #4facfe;background-color: transparent;}
.btn-outline-color:hover, .btn-outline-color:active, .btn-outline-color:focus {color: #fff;border: 2px solid #4facfe;background-color: #4facfe;}
.btn-outline {color: #212121;border:2px solid #e0e0e0;background-color: transparent;}
.btn-outline:hover, .btn-outline:active, .btn-outline:focus {color: #212121;border: 2px solid #212121;background-color: transparent;}
.btn-light {color: #212121;border-color: #fff;background-color: #fff;}
.btn-light:hover, .btn-light:active, .btn-light:focus {color: #fff;border-color: #212121;background-color: #212121;}
.btn-light-outline {color: #fff;border-color: #fff;background-color: transparent;}
.btn-light-outline:hover, .btn-light-outline:active, .btn-light-outline:focus {color: #212121;border-color: #fff;background-color: #fff;}
.btn-dark {color: #fff;border-color: #212121;background-color: #212121;}
.btn-dark:hover, .btn-dark:active, .btn-dark:focus {color: #fff;border-color: #000;background-color: #000;}
.btn-dark-outline {color: #212121;border-color: #212121;background-color: transparent;}
.btn-dark-outline:hover, .btn-dark-outline:active, .btn-dark-outline:focus {color: #fff;border-color: #212121;background-color: #212121;}
.btn-gradient-primary{color: #fff;background-color: #4facfe;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.btn-gradient-primary:after{content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;
background: rgba(79,172,254,1);background: -moz-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(252,103,103,1)), color-stop(100%, rgba(236,0,140,1)));
background: -webkit-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);background: -o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -ms-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);background: linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#00f2fe', GradientType=1 );}
.btn-gradient-primary:hover, .btn-gradient-primary:active, .btn-gradient-primary:focus{color:#fff;}
.btn-gradient-primary:hover:after, .btn-gradient-primary:active:after, .btn-gradient-primary:focus:after{visibility: visible;opacity: 1;}
.btn-gradient-secondary{color: #fff;background-color: #ec77ab;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.btn-gradient-secondary:after{content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;
background: rgba(236,119,171);background: -moz-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(236,119,171)), color-stop(100%, rgba(115,75,109,1)));
background: -webkit-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);background: -o-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);background: -ms-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec77ab', endColorstr='#734b6d', GradientType=1 );}
.btn-gradient-secondary:hover, .btn-gradient-secondary:active, .btn-gradient-secondary:focus{color:#fff;}
.btn-gradient-secondary:hover:after, .btn-gradient-secondary:active:after, .btn-gradient-secondary:focus:after{visibility: visible;opacity: 1;}
.btn-gradient-three{color: #fff;background-color: #ca4976;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.btn-gradient-three:after{content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;background: rgba(202,73,118,1);
background: -moz-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(202,73,118,1)), color-stop(100%, rgba(255,84,84,1)));
background: -webkit-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);background: -o-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);background: -ms-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ca4976', endColorstr='#ff5454', GradientType=1 );}
.btn-gradient-three:hover, .btn-gradient-three:active, .btn-gradient-three:focus{color:#fff;}
.btn-gradient-three:hover:after, .btn-gradient-three:active:after, .btn-gradient-three:focus:after{visibility: visible;opacity: 1;}
.btn-gradient-four{color: #fff;background-color: #CE9FFC;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.btn-gradient-four:after{content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;background: rgba(206,159,252,1);
background: -moz-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(206,159,252,1)), color-stop(100%, rgba(115,103,240,1)));
background: -webkit-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);background: -o-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);background: -ms-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CE9FFC', endColorstr='#7367F0', GradientType=1 );}
.btn-gradient-four:hover, .btn-gradient-four:active, .btn-gradient-four:focus{color:#fff;}
.btn-gradient-four:hover:after, .btn-gradient-four:active:after, .btn-gradient-four:focus:after{visibility: visible;opacity: 1;}
.btn-gradient-five{color: #fff;background-color: #2cc99d;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.btn-gradient-five:after{content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;background: #9357cc;background: -webkit-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);
background: -moz-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);background: -o-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);background: -ms-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);
background: linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);}
.btn-gradient-five:hover, .btn-gradient-five:active, .btn-gradient-five:focus{color:#fff;}
.btn-gradient-five:hover:after, .btn-gradient-five:active:after, .btn-gradient-five:focus:after{visibility: visible;opacity: 1;}

.btn-gradient-six{color: #fff;background: rgba(247,157,0,1);position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.btn-gradient-six:after{content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;background: rgba(247,157,0,1);
background: -moz-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(10,243,140,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(247,157,0,1)), color-stop(100%, rgba(10,243,140,1)));
background: -webkit-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
background: -o-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
background: -ms-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
background: linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f79d00', endColorstr='#64f38c', GradientType=1 );}
.btn-gradient-six:hover, .btn-gradient-six:active, .btn-gradient-six:focus{color:#fff;}
.btn-gradient-six:hover:after, .btn-gradient-six:active:after, .btn-gradient-six:focus:after{visibility: visible;opacity: 1;}

.btn-yellow {color: #fff;background-color: #f6d365;}
.btn-yellow:hover, .btn-yellow:active, .btn-yellow:focus {background-color: #fda085;color: #fff;}
.btn-purple {color: #fff;background-color: #642B73;}
.btn-purple:hover, .btn-purple:active, .btn-purple:focus {background-color: #642B73;color: #fff;}
.btn-animate {text-align: center;position: relative;overflow: hidden;transition: all 0.2s;}
.btn-animate  span {display: inline-block;position: relative;padding: 0 8.5px 0 8.5px;transition: padding 0.2s;}
.btn-animate i {position: absolute;top: 50%;right: 0px;opacity: 0;margin-top: -6px;transition: opacity 0.2s, right 0.2s;}
.btn-animate:hover span {padding: 0 17px 0 0;}
.btn-animate:hover i {transition: opacity 0.2s, right 0.2s;opacity: 1;top: 50%;right: 0px;}

/* Icons */

.tr-icon {display: inline-block;font-style: normal !important;font-weight: normal !important;font-variant: normal !important;text-transform: none !important;speak: none;line-height: 1;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
.icon {margin-left:6px;}
.feature-box, .feature-box-right {float:left;}
.feature-box p {margin-bottom: 0;}
.feature-box i {margin-bottom:20px;display: inline-block;}
.box-icon {margin-top:25px;margin-bottom:25px;position:relative;background-color:#fff;padding:40px 30px;-webkit-box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);-moz-box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);
box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);-webkit-transition: all 0.4s ease;-moz-transition: all 0.4s ease;-o-transition: all 0.4s ease;transition: all 0.4s ease;}
.box-icon:hover {-webkit-transform: translateY(-10px);-moz-transform: translateY(-10px);-ms-transform: translateY(-10px);-o-transform: translateY(-10px);transform: translateY(-10px);
-webkit-box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);-moz-box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);}
.box-icon h6 {font-size:13px;}
.box-icon i {margin-bottom:25px;}
.white-bg-icon {background-color: #fff;border-color: #fff;}
.white-bg-icon:hover {background-color: #4facfe;border-color: #4facfe;color:#fff!important;}
.border-bg-default-color {border:2px solid #212121;}
.border-bg-default-color:hover {background-color: #4facfe;border-color: #4facfe;color: #fff;}
.gradient-bg-icon {background: rgba(79,172,254,1);background: -moz-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(242,112,156,1)), color-stop(100%, rgba(255,148,114,1)));background: -webkit-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);background: -ms-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);background: linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#00f2fe', GradientType=1 );display: inline-block!important;line-height: 100px!important;cursor: pointer;width: 85px;height: 85px;border-radius: 50%;text-align: center;position: relative;text-decoration: none;z-index: 1;}
.gradient-bg-icon:after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(0);-moz-transform: scale(0);-ms-transform: scale(0);transform: scale(0);border-radius: 50%;    -webkit-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;background: rgba(236,119,171);background: -moz-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(236,119,171)), color-stop(100%, rgba(115,75,109,1)));background: -webkit-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -o-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);background: -ms-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);background: linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec77ab', endColorstr='#734b6d', GradientType=1 );}
.gradient-bg-icon:hover::after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(1);-moz-transform: scale(1);-ms-transform: scale(1);transform: scale(1);
background: rgba(236,119,171);
background: -moz-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(236,119,171)), color-stop(100%, rgba(115,75,109,1)));
background: -webkit-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -o-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -ms-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec77ab', endColorstr='#734b6d', GradientType=1 );z-index: -1;}
.gradient-bg-icon-two {
background: rgba(236,119,171);
background: -moz-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(236,119,171)), color-stop(100%, rgba(115,75,109,1)));
background: -webkit-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -o-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -ms-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec77ab', endColorstr='#734b6d', GradientType=1 );
display: inline-block!important;line-height: 100px!important;cursor: pointer;width: 85px;height: 85px;border-radius: 50%;text-align: center;position: relative;text-decoration: none;z-index: 1;}
.gradient-bg-icon-two:after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(0);-moz-transform: scale(0);-ms-transform: scale(0);transform: scale(0);border-radius: 50%;    -webkit-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;background: rgba(79,172,254,1);
background: -moz-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(242,112,156,1)), color-stop(100%, rgba(255,148,114,1)));
background: -webkit-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -ms-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#ff9472', GradientType=1 );}
.gradient-bg-icon-two:hover::after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(1);-moz-transform: scale(1);-ms-transform: scale(1);transform: scale(1);
background: rgba(79,172,254,1);
background: -moz-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(242,112,156,1)), color-stop(100%, rgba(255,148,114,1)));
background: -webkit-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -ms-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#ff9472', GradientType=1 );z-index: -1;}
.gradient-bg-icon-three {
background: rgba(202,73,118,1);
background: -moz-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(202,73,118,1)), color-stop(100%, rgba(255,84,84,1)));
background: -webkit-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: -o-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: -ms-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ca4976', endColorstr='#ff5454', GradientType=1 );
display: inline-block!important;line-height: 100px!important;cursor: pointer;width: 85px;height: 85px;border-radius: 50%;text-align: center;position: relative;text-decoration: none;z-index: 1;}
.gradient-bg-icon-three:after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(0);-moz-transform: scale(0);-ms-transform: scale(0);transform: scale(0);border-radius: 50%;    -webkit-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;background: rgba(206,159,252,1);
background: -moz-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(206,159,252,1)), color-stop(100%, rgba(115,103,240,1)));
background: -webkit-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -o-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -ms-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CE9FFC', endColorstr='#7367F0', GradientType=1 );}
.gradient-bg-icon-three:hover::after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(1);-moz-transform: scale(1);-ms-transform: scale(1);transform: scale(1);
background: rgba(206,159,252,1);
background: -moz-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(206,159,252,1)), color-stop(100%, rgba(115,103,240,1)));
background: -webkit-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -o-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -ms-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CE9FFC', endColorstr='#7367F0', GradientType=1 );z-index: -1;}
.gradient-bg-icon-four {background: #2cc99d;display: inline-block!important;line-height: 100px!important;cursor: pointer;width: 85px;height: 85px;border-radius: 50%;text-align: center;position: relative;text-decoration: none;z-index: 1;}
.gradient-bg-icon-four:after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(0);-moz-transform: scale(0);-ms-transform: scale(0);transform: scale(0);border-radius: 50%;-webkit-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;background: #9357cc;background: -webkit-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);background: -moz-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);
background: -o-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);background: -ms-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);background: linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);}
.gradient-bg-icon-four:hover::after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(1);-moz-transform: scale(1);-ms-transform: scale(1);transform: scale(1);
background: #9357cc;background: -webkit-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);background: -moz-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);
background: -o-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);background: -ms-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);background: linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);z-index: -1;}
.gradient-bg-icon-five {display: inline-block!important;line-height: 100px!important;cursor: pointer;width: 85px;height: 85px;border-radius: 50%;text-align: center;position: relative;text-decoration: none;z-index: 1;
background: rgba(246,211,101,1);background: -moz-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(246,211,101,1)), color-stop(100%, rgba(253,160,133,1)));background: -webkit-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
background: -o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(253,242,254,1) 100%);background: -ms-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);background: linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6d365', endColorstr='#fda085', GradientType=1 );}
.gradient-bg-icon-five:after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(0);-moz-transform: scale(0);-ms-transform: scale(0);transform: scale(0);border-radius: 50%;-webkit-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;background: rgba(100,43,115,1);background: -moz-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(100,43,115,1)), color-stop(100%, rgba(198,66,110,1)));background: -webkit-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
background: -o-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);background: -ms-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);background: linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#C6426E', endColorstr='#642B73', GradientType=1 );}
.gradient-bg-icon-five:hover::after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(1);-moz-transform: scale(1);-ms-transform: scale(1);transform: scale(1);
background: rgba(100,43,115,1);background: -moz-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(100,43,115,1)), color-stop(100%, rgba(198,66,110,1)));background: -webkit-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
background: -o-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);background: -ms-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);background: linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#C6426E', endColorstr='#642B73', GradientType=1 );z-index: -1;}

.gradient-bg-icon-six {display: inline-block!important;line-height: 100px!important;cursor: pointer;width: 85px;height: 85px;border-radius: 50%;text-align: center;position: relative;text-decoration: none;z-index: 1;
background: rgba(247,157,0,1);
background: -moz-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(10,243,140,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(247,157,0,1)), color-stop(100%, rgba(10,243,140,1)));
background: -webkit-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
background: -o-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
background: -ms-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
background: linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f79d00', endColorstr='#64f38c', GradientType=1 );}
.gradient-bg-icon-six:after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(0);-moz-transform: scale(0);-ms-transform: scale(0);transform: scale(0);border-radius: 50%;-webkit-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;background: rgba(61,82,82,1);
background: -moz-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(61,82,82,1)), color-stop(100%, rgba(222,203,164,1)));
background: -webkit-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: -o-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: -ms-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e5151', endColorstr='#decba4', GradientType=1 );}
.gradient-bg-icon-six:hover::after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(1);-moz-transform: scale(1);-ms-transform: scale(1);transform: scale(1);
background: rgba(61,82,82,1);
background: -moz-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(61,82,82,1)), color-stop(100%, rgba(222,203,164,1)));
background: -webkit-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: -o-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: -ms-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e5151', endColorstr='#decba4', GradientType=1 );z-index:-1;}

.default-bg-icon{background-color: #4facfe;display: inline-block!important;line-height: 100px!important;cursor: pointer;width: 85px;height: 85px;border-radius: 50%;text-align: center;position: relative;text-decoration: none;z-index: 1;}
.default-bg-icon:after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(0);-moz-transform: scale(0);-ms-transform: scale(0);transform: scale(0);border-radius: 50%;    -webkit-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;background-color: #ec77ab;}
.default-bg-icon:hover:after{content: '';position: absolute;left: 0;top: 0;right: 0;width: 100%;height: 100%;-webkit-transform: scale(1);-moz-transform: scale(1);-ms-transform: scale(1);transform: scale(1);z-index: -1;}
.feature-box .pull-left{width:15%;}
.feature-box .pull-right{width:80%;}
.feature-box-right .pull-left{width:80%;}
.feature-box-right .pull-right{width:15%;}
.feature-box-big .pull-left{width:20%;}
.feature-box-big .pull-right{width:80%;}
.feature-box-rounded{background-color:#fff;border-radius:50px;-webkit-border-radius:50px;-moz-border-radius:50px;padding:30px;-webkit-box-shadow:-1px 0px 43px 0px rgba(0,0,0,0.14);-moz-box-shadow:-1px 0px 43px 0px rgba(0,0,0,0.14);box-shadow:-1px 0px 43px 0px rgba(0,0,0,0.14);}
.feature-box-gradient{background-color: #4facfe;padding: 50px 30px;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.feature-box-gradient:after {content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;background: rgba(79,172,254,1);
background: -moz-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(252,103,103,1)), color-stop(100%, rgba(236,0,140,1)));
background: -webkit-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -ms-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#00f2fe', GradientType=1 );}
.feature-box-gradient:hover:after{visibility: visible;opacity: 1;}
.feature-box-gradient-two{background-color: #ec77ab;padding: 50px 30px;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.feature-box-gradient-two:after {content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;background: rgba(236,119,171);
background: -moz-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(236,119,171)), color-stop(100%, rgba(115,75,109,1)));
background: -webkit-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -o-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -ms-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec77ab', endColorstr='#734b6d', GradientType=1 );}
.feature-box-gradient-two:hover:after{visibility: visible;opacity: 1;}
.feature-box-gradient-three{background-color: #ca4976;padding: 50px 30px;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.feature-box-gradient-three:after {content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;background: rgba(202,73,118,1);
background: -moz-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(202,73,118,1)), color-stop(100%, rgba(255,84,84,1)));
background: -webkit-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: -o-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: -ms-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ca4976', endColorstr='#ff5454', GradientType=1 );}
.feature-box-gradient-three:hover:after{visibility: visible;opacity: 1;}
.feature-box-gradient-four{background-color: #CE9FFC;padding: 50px 30px;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.feature-box-gradient-four:after {content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;background: rgba(206,159,252,1);
background: -moz-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(206,159,252,1)), color-stop(100%, rgba(115,103,240,1)));
background: -webkit-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -o-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -ms-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CE9FFC', endColorstr='#7367F0', GradientType=1 );}
.feature-box-gradient-four:hover:after{visibility: visible;opacity: 1;}
.feature-box-gradient-five{background-color: #f6d365;padding: 50px 30px;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.feature-box-gradient-five:after {content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;background: rgba(246,211,101,1);
background: -moz-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(246,211,101,1)), color-stop(100%, rgba(253,160,133,1)));
background: -webkit-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
background: -o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(253,242,254,1) 100%);
background: -ms-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
background: linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#00f2fe', GradientType=1 );}
.feature-box-gradient-five:hover:after{visibility: visible;opacity: 1;}
.feature-box-gradient-six{background-color: #642B73;padding: 50px 30px;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.feature-box-gradient-six:after {content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;background: rgba(100,43,115,1);
background: -moz-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(100,43,115,1)), color-stop(100%, rgba(198,66,110,1)));
background: -webkit-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
background: -o-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
background: -ms-linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
background: linear-gradient(45deg, rgba(100,43,115,1) 0%, rgba(198,66,110,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#642B73', endColorstr='#c6426e', GradientType=1 );}
.feature-box-gradient-six:hover:after{visibility: visible;opacity: 1;}

.feature-box-gradient-seven{background-color: #f79d00;padding: 50px 30px;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.feature-box-gradient-seven:after {content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;background: rgba(247,157,0,1);
background: -moz-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(10,243,140,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(247,157,0,1)), color-stop(100%, rgba(10,243,140,1)));
background: -webkit-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
background: -o-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
background: -ms-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
background: linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f79d00', endColorstr='#64f38c', GradientType=1 );}
.feature-box-gradient-seven:hover:after{visibility: visible;opacity: 1;}

.feature-box-gradient-eight{background-color: #3e5151;padding: 50px 30px;position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.feature-box-gradient-eight:after {content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;background: rgba(61,82,82,1);
background: -moz-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(61,82,82,1)), color-stop(100%, rgba(222,203,164,1)));
background: -webkit-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: -o-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: -ms-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e5151', endColorstr='#decba4', GradientType=1 );}
.feature-box-gradient-eight:hover:after{visibility: visible;opacity: 1;}
.hover-effect {transition: all 0.3s ease-in-out;}
.hover-effect:hover {transform: translateY(-1rem);}

/* Counters */

.counter-wrap{margin:25px 0;text-align:center;width: 100%;}
.counter-wrap h2{margin:15px 0 20px;font-size:35px;line-height:50px;text-align:center;}
.counter-wrap h3{margin:0;font-size:16px;line-height:18px;text-align:center;color:#f1f1f1;font-family:'Montserrat', sans-serif;}
.counter-type-2{padding-left:75px;}
.counter-type-2 i{position:absolute;top:-1px;left:0;}
.counter-type-3 .counter-wrap{text-align:left;float:left;}
.counter-style-two .counter-wrap i{font-size:60px;}
.counter-type-3 .counter-wrap h2{text-align:left;display:inline-block;}
.counter-type-3 .counter-wrap h2{font-size:30px;margin:-10px 0 0 0;}
.counter-type-3 .counter-wrap .float-start .line{height:1px;width:40px;margin-bottom:10px;margin-top:5px;}

/* Portfolio */

.portfolio .folio-overlay {background-color: rgba(242, 112, 156, 0.9);position: absolute;width: 100%;height: 100%;opacity: 0;visibility:hidden;transition-timing-function: ease-in-out;
-ms-transition-timing-function: ease-in-out;-moz-transition-timing-function: ease-in-out;-webkit-transition-timing-function: ease-in-out;-o-transition-timing-function: ease-in-out;transition-duration: .2s;
-ms-transition-duration: .2s;-moz-transition-duration: .2s;-webkit-transition-duration: .2s;-o-transition-duration: .2s;}
.portfolio.with-spacing{padding: 10px;}
.portfolio:hover .folio-overlay{opacity: 1;visibility:visible;}
.portfolio-wrap {position: absolute;top: 50%;left: 0;width: 100%;margin-top: -75px;}
.portfolio-description .portfolio-title,
.portfolio-description .links {position: relative;left: 0;color: #fff;width: 100%;opacity: 0;visibility: hidden;}
.portfolio-description .portfolio-title {top: 0;-webkit-transition: 0.3s;-moz-transition: 0.3s;-o-transition: 0.3s;transition: 0.3s;font-size: 18px;letter-spacing: 1px;font-weight: 500;font-family: 'Open Sans', sans-serif;;}
.portfolio:hover .portfolio-description .portfolio-title {top: 30px;opacity: 1;visibility: visible;}
.portfolio-description .links {top: 0px;-webkit-transition: 0.4s;-moz-transition: 0.4s;-o-transition: 0.4s;transition: 0.4s;text-transform: uppercase;font-size: 14px;letter-spacing: 1px;font-weight: 300;}
.portfolio:hover .portfolio-description .links {top: 25px;opacity: 1;visibility: visible;}
.portfolio-description .btn {background-color: transparent;;border-color: #fff;padding: 12px 22px;border-radius: 20px;}
.portfolio-description .btn:hover {background-color: #fff;;border-color: #fff;color: #ff2a40;}
.portfolio-container .btn.view-more {margin-top: 40px;}
.hover-type-2 .portfolio-wrap {margin-top: -110px;}
.hover-type-2 .portfolio-details{top:35px;}
.portfolio-details {position: relative;left: 0;top: 50px;width: 100%;}
.portfolio-details li {display: inline-block;position: relative;bottom: -40px;opacity: 0;visibility: hidden;transform: translateZ(0);margin-right: 15px;}
.portfolio-details li:last-child {margin-right:0;}
.portfolio:hover .portfolio-details li {bottom: 0;opacity: 1;visibility: visible;}
.portfolio:hover .portfolio-details li:nth-child(1) {-webkit-transition: 0.3s;-moz-transition: 0.3s;-o-transition: 0.3s;transition: 0.3s;}
.portfolio:hover .portfolio-details li:nth-child(2){-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;transition: 0.5s;}
.portfolio-details li a {display: block;color: #f1f1f1;border: 2px solid #fff;border-radius: 100px;width: 50px;height: 50px;line-height: 48px;}
.portfolio-details li a:hover{display: block;color:#4facfe;border: 2px solid #fff;background-color: #fff;}
.portfolio-type-2 a{color: #fff;font-size: 40px;}
.row.no-gutters{margin-right: 0;margin-left: 0;}
.no-bottom-padding{padding-bottom: 0;}
.no-gutters > [class^="col-"], .no-gutters > [class*="col-"], .row.no-gutters > [class^="col-"], .row.no-gutters > [class*="col-"]{padding-right: 0;padding-left: 0;}

/* Portfolio Style 02 */

.portfolio-item {float: left;position: relative;overflow: hidden;}
.portfolio-item .portfolio-detail > a {display: block;position: relative;overflow: hidden;}
.portfolio-item img {-webkit-transition: all 1s ease;transition: all 1s ease;}
.portfolio-item:hover img {-webkit-transform: scale(1.2) translateY(-20px);transform: scale(1.2) translateY(-20px);}
.portfolio-item:hover .portfolio-info {opacity: 1;-webkit-transform: translateY(0);transform: translateY(0);}
.portfolio-item:hover .portfolio-info h3, .portfolio-item:hover .portfolio-info p {-webkit-transform: scale(1);transform: scale(1);opacity: 1;}
.portfolio-item:hover .portfolio-info h3:after {width: 60px;opacity: 1;}
.portfolio-item:hover .portfolio-info-alt h3 > a {color: #00c3da;}
.portfolio-info {position: absolute;top: auto;right: 0px;bottom: 0px;left: 0px;padding: 25px;-webkit-transition: all .3s ease;transition: all .3s ease;opacity: 0;text-align: center;
box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);-webkit-transform: translateY(30px);transform: translateY(30px);}
.portfolio-info .v-center {vertical-align: bottom;}
.portfolio-info h3 {font-size: 20px;font-weight: 700;-webkit-transform: scale(0.8);transform: scale(0.8);-webkit-transition: all .4s ease;transition: all .4s ease;-webkit-transition-delay: .3s;
transition-delay: .3s;opacity: 0;}
.portfolio-info p {font-size: 14px;-webkit-transform: scale(0.8);transform: scale(0.8);-webkit-transition: all .4s ease;transition: all .4s ease;-webkit-transition-delay: .6s;transition-delay: .6s;opacity: 0;}
.portfolio-info-alt {padding: 20px 0;}
.portfolio-info-alt h3 {font-size: 16px;font-weight: 600;letter-spacing: -0.05em;margin-top: 0;}
.portfolio-info-alt h3 > a {-webkit-transition: all .3s ease;transition: all .3s ease;color: #1f1f1f;}
.portfolio-info-alt p {color: #787878;font-size: 14px;margin-bottom: 5px;}
.project-info > ul {list-style: none;margin: 0;padding: 0;}
.project-info > ul > li {display: block;margin-bottom: 15px;color: #1f1f1f;font-size: 14px;}
.project-info > ul > li > strong {display: block;color: #929292;text-transform: uppercase;letter-spacing: 0.05em;font-size: 11px;font-weight: 500;padding-bottom: 6px;}
.project-info > ul > li > a {color: #1f1f1f;}
.project-info > ul > li > a:hover {color: #4facfe;}
.masonry-grid {display: grid;grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));grid-gap: 15px}
.masonry-item {break-inside: avoid;}

/* Portfolio Single Project 01 */

.portfolio-meta > li {margin: 25px 0;list-style: none;border-bottom: 1px solid #f5f7fa;padding-bottom: 15px;}
.portfolio-meta > li:last-child{border-bottom: 0;}
.portfolio-meta > li span {display: inline-block;width: 130px;letter-spacing: 1px;color: #212121;font-size: 12px;text-transform: uppercase;font-weight: 600;}
.portfolio-meta > li a {margin-right: 10px;color: #a7a7a7;}
.portfolio-meta > li a:hover {color: #4facfe;}

/* Portfolio Single Project 02 */

.portfolio-meta-style-02 .portfolio-meta > li {margin: 0px 0 10px 0;list-style: none;padding-bottom: 0px;border-bottom:0;}
.portfolio-meta-style-02 .portfolio-meta > li span {display: inline-block;width: 130px;letter-spacing: 1px;color: #fff;font-size: 12px;text-transform: uppercase;font-weight: 600;}
.portfolio-meta-style-02 .portfolio-meta > li a {margin-right: 10px;color: #a7a7a7;}
.portfolio-meta-style-02 .portfolio-meta > li a:hover {color: #4facfe;}

/* Portfolio Single Project 03 */

.portfolio-meta-style-03 .portfolio-meta > li {margin:0px 0 25px 0;list-style: none;border-bottom: 1px solid #dedede;padding-bottom: 15px;}
.portfolio-meta-style-03 .portfolio-meta > li:last-child{border-bottom: 0;}
.portfolio-meta-style-03 .portfolio-meta > li span {display: inline-block;width: 130px;letter-spacing: 1px;color: #212121;font-size: 12px;text-transform: uppercase;font-weight: 600;}
.portfolio-meta-style-03 .portfolio-meta > li a {margin-right: 10px;color: #a7a7a7;}
.portfolio-meta-style-03 .portfolio-meta > li a:hover {color: #4facfe;}

/* Video Popup CSS */

.video-popup {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  position: fixed;
  text-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.video-popup .popup-inner {
  width: 720px;
  padding: 20px;
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
}
.video-popup .popup-inner iframe {
  width: 100%;
  height: 426px;
  border-radius: 5px;
}
.video-popup .popup-inner .close-btn {
  top: -15px;
  width: 35px;
  right: -10px;
  border: none;
  height: 35px;
  font-size: 20px;
  line-height: 37px;
  border-radius: 50%;
  position: absolute;
  transition: var(--transition);
  background-color: #fff;
}
.video-popup .popup-inner .close-btn:hover {
  background-color: red;
  color: #fff;
}
/* Max width 767px */
@media only screen and (max-width: 767px) {
  .video-popup .popup-inner {
    width: 300px;
    padding: 10px;
 }
  .video-popup .popup-inner iframe {
    width: 100%;
    height: 177px;
 }
  .video-popup .popup-inner .close-btn {
    width: 30px;
    right: -5px;
    height: 30px;
    font-size: 18px;
    line-height: 31px;
 }
  .video-box {
    padding-top: 150px;
    padding-bottom: 150px;
 }
  .video-box .video-btn {
    width: 65px;
    height: 65px;
    font-size: 40px;
 }
  .video-box .video-btn i {
    margin-top: 1px;
 }
}


.project-info{list-style:none;padding-top:50px;text-align:left;}
.project-info li{font-family:'Montserrat', sans-serif;font-size:14px;font-weight:700;text-transform:uppercase;letter-spacing:2px;margin:15px 0;color:#212121;}
.project-info li span{font-family:'Montserrat', sans-serif;color:#6d747c;font-size:13px;text-transform:uppercase;font-weight:300;letter-spacing:1px;}
.project-title{padding-top:20px;font-family:'Montserrat', sans-serif;text-transform:uppercase;text-align:left;font-size:20px;font-weight:400;letter-spacing:2px;color:#212121;margin-bottom:20px;}
.project-details{text-align:left;font-size:16px;font-weight:300;line-height:1.8;padding-bottom:50px;}
.project-details p{color:#212121;font-size:16px;font-weight:300;margin-top:35px;}
.portfolio-slider{margin-bottom:30px;}
.portfolio-slider .slick-dots{position:absolute;bottom:25px;}
.portfolio-right .project-detail-box{margin-top:10px;}
.portfolio-right .project-detail-box.mt-50{margin-top:50px;}
.portfolio-right .project-detail-box li strong{min-width:110px;display:inline-block;}
.portfolio-right .project-detail-box li strong.display-block{display:block;}
.portfolio-right .project-detail-box li{line-height:30px;list-style:none;}
.portfolio-right .project-detail-box li a{color:#888;}
.portfolio-right .project-detail-box li a:hover{color:#4facfe;}
.project-type-list{margin-top:30px;}
.project-type-list li{padding:10px 0;border-bottom:1px solid #E8E8E8;font-weight:300;font-size:14px;list-style:none;}
.project-type-list li i{margin-right:10px;}
.portfolio-right .project-type-list li, .portfolio-right .project-detail-box li{text-transform:uppercase;}
.portfolio-nav a{overflow:hidden;color:inherit;cursor:default;display:block;}
.portfolio-nav a.disabled{opacity:.6;pointer-events:none;}
.portfolio-nav a.disabled > span{cursor:default;}
.portfolio-nav a.disabled > span:hover{color:inherit;}
.portfolio-nav a.disabled:hover{opacity:.4;}
.portfolio-nav a.prev i{margin-right:10px;}
.portfolio-nav a.showall{text-align:center;}
.portfolio-nav a.showall span{padding:36px 0;}
.portfolio-nav a.showall span i{font-size:24px;display:block;}
.portfolio-nav a.showall:hover span i{transform:scale(1.1);-webkit-transform:scale(1.1);-moz-transform:scale(1.1);}
@media only screen and (max-width:767px){.portfolio-nav a.showall span i{font-size:30px;}
}
.portfolio-nav a.next{text-align:right;}
.portfolio-nav a.next i{margin-left:10px;}
.portfolio-nav a > span{display:inline-block;padding:40px 0;font-size:12px;cursor:pointer;text-transform:uppercase;font-family:'Montserrat', sans-serif;font-weight:500;letter-spacing:1px;-webkit-transition:all .3s ease;transition:all .3s ease;}
.portfolio-nav a > span:hover{color:#4facfe;}
.portfolio-nav a > span i{font-size:14px;}
.portfolio-nav a > span i:before{vertical-align:middle;}
@media only screen and (max-width:767px){.portfolio-nav a > span{font-size:0;}
 .portfolio-nav a > span i{font-size:30px;}
}

/* Pricing Table */
.pricing-box {padding: 40px;position: relative;}
.pricing-box-gradient, .pricing-box-gradient-two, .pricing-box-gradient-three, .pricing-box-gradient-four, .pricing-box-gradient-five, .pricing-box-gradient-six, .pricing-box-gradient-seven, .pricing-box-gradient-eight, .pricing-box-gradient-nine, .pricing-box-gradient-ten, .pricing-box-purple, .pricing-box-green, .pricing-box-secondary, .pricing-box-default{position: relative;-webkit-transition: all .5s ease .1s;-o-transition: all .5s ease .1s;transition: all .5s ease .1s;z-index: 2;overflow: hidden;}
.pricing-box-gradient:after, .pricing-box-gradient-two:after, .pricing-box-gradient-three:after, .pricing-box-gradient-four:after, .pricing-box-gradient-five:after, .pricing-box-gradient-seven:after, .pricing-box-gradient-eight:after, .pricing-box-gradient-nine:after, .pricing-box-gradient-ten:after, .pricing-box-purple:after, .pricing-box-green:after, .pricing-box-secondary:after, .pricing-box-default:after{content: '';position: absolute;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;z-index: -1;}
.pricing-box-gradient:hover:after, .pricing-box-gradient-two:hover:after, .pricing-box-gradient-three:hover:after, .pricing-box-gradient-four:hover:after, .pricing-box-gradient-four:hover:after, .pricing-box-gradient-five:hover:after, .pricing-box-gradient-six:hover:after, .pricing-box-gradient-seven:hover:after, .pricing-box-gradient-eight:hover:after, .pricing-box-gradient-nine:hover:after, .pricing-box-gradient-ten:hover:after, .pricing-box-purple:hover:after, .pricing-box-green:hover:after, .pricing-box-secondary:hover:after, .pricing-box-default:hover:after{visibility: visible;opacity: 1;}

.pricing-box-gradient{background-color: #4facfe;}
.pricing-box-gradient:after{background: rgba(79,172,254,1);background: -moz-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);background: -webkit-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: -o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);background: -ms-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
background: linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#00f2fe', GradientType=1 );}

.pricing-box-gradient-two{background-color: #ec77ab;}
.pricing-box-gradient-two:after{background: rgba(236,119,171);background: -moz-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -webkit-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);background: -o-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
background: -ms-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);background: linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec77ab', endColorstr='#734b6d', GradientType=1 );}

.pricing-box-gradient-three{background-color: #ca4976;}
.pricing-box-gradient-three:after{background: rgba(202,73,118,1);background: -moz-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: -webkit-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);background: -o-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
background: -ms-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);background: linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ca4976', endColorstr='#ff5454', GradientType=1 );}

.pricing-box-gradient-four{background-color: #CE9FFC;}
.pricing-box-gradient-four:after{background: rgba(206,159,252,1);background: -moz-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -webkit-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);background: -o-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
background: -ms-linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);background: linear-gradient(45deg, rgba(206,159,252,1) 0%, rgba(115,103,240,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CE9FFC', endColorstr='#7367F0', GradientType=1 );}

.pricing-box-gradient-five{background-color: #232526;}
.pricing-box-gradient-five:after{background: rgba(35,37,38,1);background: -moz-linear-gradient(45deg, rgba(35,37,38,1) 0%, rgba(65,67,69,1) 100%);
background: -webkit-linear-gradient(45deg, rgba(35,37,38,1) 0%, rgba(65,67,69,1) 100%);background: -o-linear-gradient(45deg, rgba(35,37,38,1) 0%, rgba(65,67,69,1) 100%);
background: -ms-linear-gradient(45deg, rgba(35,37,38,1) 0%, rgba(65,67,69,1) 100%);background: linear-gradient(45deg, rgba(35,37,38,1) 0%, rgba(65,67,69,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#232526', endColorstr='#414345', GradientType=1 );}

.pricing-box-gradient-six{background-color: rgba(246,211,101,1);}
.pricing-box-gradient-six:after{background: rgba(246,211,101,1);background: -moz-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
background: -webkit-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);background: -o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(253,242,254,1) 100%);
background: -ms-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);background: linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6d365', endColorstr='#fda085', GradientType=1 );}

.pricing-box-gradient-seven{background-color: rgba(100,43,115,1);}
.pricing-box-gradient-seven:after{background: rgba(100,43,115,1);background: -moz-linear-gradient(45deg, rgba(100,43,115,0.5) 0%, rgba(198,66,110,0.5) 100%);
background: -webkit-linear-gradient(45deg, rgba(100,43,115,0.5) 0%, rgba(198,66,110,0.5) 100%);background: -o-linear-gradient(45deg, rgba(100,43,115,0.5) 0%, rgba(198,66,110,0.5) 100%);
background: -ms-linear-gradient(45deg, rgba(100,43,115,0.5) 0%, rgba(198,66,110,0.5) 100%);background: linear-gradient(45deg, rgba(100,43,115,0.5) 0%, rgba(198,66,110,0.5) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#642b73', endColorstr='#c6426e', GradientType=1 );}

.pricing-box-purple{background-color: #fff;}
.pricing-box-purple:after{background-color: #9357cc;}

.pricing-box-gradient-eight{background: rgba(247,157,0,1);}
.pricing-box-gradient-eight:after{background: rgba(247,157,0,1);background: -moz-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(10,243,140,1) 100%);
background: -webkit-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);background: -o-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
background: -ms-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);background: linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f79d00', endColorstr='#64f38c', GradientType=1 );}

.pricing-box-gradient-nine{background: rgba(61,82,82,1);}
.pricing-box-gradient-nine:after{background: rgba(61,82,82,1);background: -moz-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: -webkit-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);background: -o-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
background: -ms-linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);background: linear-gradient(45deg, rgba(61,82,82,1) 0%, rgba(222,203,164,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e5151', endColorstr='#decba4', GradientType=1 );}

.pricing-box-green{background-color: #fff;}
.pricing-box-green:after{background-color: #2cc99d;}

.pricing-box-green.pricing-box h2, .pricing-box-green .list-style-02 li:before{color:#2cc99d;}
.pricing-box-gradient .list-style-02 li:before, .pricing-box-gradient-two .list-style-02 li:before, .pricing-box-gradient-three .list-style-02 li:before, .pricing-box-gradient-four .list-style-02 li:before, .pricing-box-gradient-five .list-style-02 li:before, .pricing-box-gradient-six .list-style-02 li:before, .pricing-box-gradient-seven .list-style-02 li:before, .pricing-box-gradient-seven .list-style-02 li.not-available:before, .pricing-box-gradient-nine .list-style-02 li:before, .pricing-box-gradient-eight .list-style-02 li:before, .pricing-box-gradient-nine .list-style-02 li.not-available:before, .pricing-box-gradient-eight .list-style-02 li.not-available:before{color:#fff;}
.pricing-box-default{background-color: #fff;}
.pricing-box-default:after{background-color: #4facfe;}

.pricing-box:hover h3, .pricing-box:hover h4, .pricing-box:hover h2, .pricing-box:hover .list-style-02 li, .pricing-box:hover .list-style-02 li:before, .pricing-box-secondary.pricing-box:hover h2,
.pricing-box-gradient .list-style-02 li.not-available:before, .pricing-box-gradient .list-style-02 li.not-available, .pricing-box-gradient-two .list-style-02 li.not-available:before, .pricing-box-gradient-two .list-style-02 li.not-available,
.pricing-box-gradient-three .list-style-02 li.not-available:before, .pricing-box-gradient-three .list-style-02 li.not-available, .pricing-box-gradient-four .list-style-02 li.not-available:before, .pricing-box-gradient-four .list-style-02 li.not-available,
.pricing-box-gradient-five .list-style-02 li.not-available:before, .pricing-box-gradient-five .list-style-02 li.not-available, .pricing-box-gradient-six .list-style-02 li.not-available:before, .pricing-box-gradient-six .list-style-02 li.not-available{color:#fff;}
.pricing-box-secondary{background-color: #fff;}
.pricing-box-secondary:after{background-color: #ec77ab;}

.pricing-box-secondary.pricing-box h2, .pricing-box-secondary .list-style-02 li:before{color:#ec77ab;}
.pricing-box h4 {font-size: 14px;color: #a5a5a5;font-weight: 300;line-height: 20px;}
.pricing-box h3{font-size:30px;}
.pricing-box h2 {margin-top: 25px;margin-bottom: 25px;color: #4facfe;}
.pricing-box h2 sup {font-size: 25px;top: -4.6px;}
.pricing-box h2 span {font-weight: 700;}
.pricing-box ul{margin:20px 0;padding:0;}
.pricing-box li{display:block;margin-bottom:10px;list-style:none;font-size:14px;}
.pricing-box-bottom{margin-top:40px;}
.pricing-table-featured {margin: 5px 0;}
.pricing-table-featured .pricicng-feature {min-height: 180px;}
.pricing-box-gradient-ten{background-color:#1e0d63;}
.pricing-box-gradient-ten:after{background: rgba(30, 13, 99, 1);
background: -moz-linear-gradient(180deg, rgba(30, 13, 99, 1) 0%, rgba(15, 1, 38, 1) 100%);background: -webkit-linear-gradient(180deg, rgba(30, 13, 99, 1) 0%, rgba(15, 1, 38, 1) 100%);
background: -o-linear-gradient(180deg, rgba(30, 13, 99, 1) 0%, rgba(15, 1, 38, 1) 100%);background: -ms-linear-gradient(180deg, rgba(30, 13, 99, 1) 0%, rgba(15, 1, 38, 1) 100%);
background: linear-gradient(180deg, rgba(30, 13, 99, 1) 0%, rgba(15, 1, 38, 1) 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1e0d63", endColorstr="#0f0126", GradientType=1);}


/* Teams */

/* Team Style 01 */
.member {margin: 40px 0;padding: 0 10px;}
.team-slider .member {margin: 20px 0 30px 0;padding: 0 10px;}
.team-fullwidth .member {margin: 0;padding: 0;}
.p0.member {margin: 0;padding: 0;}
.team-member-container {position: relative;overflow: hidden;}
.team-member-img img {width: 100%;}
.team-member-container .member-caption {width: 100%;position: absolute;z-index: 2;height: 100%;opacity: 0;visibility:hidden;top: 0;background-color: rgba(191, 7, 49, 0.8);}
.team-member-container:hover .member-caption {opacity: 1;visibility:visible;}
.member-description {width: 100%;height: 100%;}
.member-description-wrap {position: absolute;top: 0%;left: 0;width: 100%;margin-top: 0px;padding: 25px;height: 100%;}
.member-description .member-title, .member-description .member-subtitle {position: relative;left: 0;color: #fff;width: 100%;opacity: 0;visibility: hidden;}
.member-description .member-title{top: 0;-webkit-transition: 0.3s;-moz-transition: 0.3s;-o-transition: 0.3s;transition: 0.3s;margin-top: -35px;}
.member-description .member-subtitle {top: 0;-webkit-transition: 0.4s;-moz-transition: 0.4s;-o-transition: 0.4s;transition: 0.4s;}
.team-member-container:hover .member-description .member-title {opacity: 1;visibility: visible;top: 30px;}
.team-member-container:hover .member-description .member-subtitle {opacity: 1;visibility: visible;top: 25px;}
.member-icons, .product-btn-group {position: absolute;left: 0;bottom: 0px;width: 100%;padding: 25px;margin-bottom: 0;}
.member-icons li, .product-btn-group li {display: inline-block;position: relative;bottom: -40px;opacity: 0;visibility: hidden;transform: translateZ(0);margin-right: 5px;}
.member-icons li a, .product-btn-group li a {display: block;color: #fff;}
.member-icons li a:hover, .product-btn-group li a:hover {color: #fff;}
.team-member-container:hover .member-icons li{opacity: 1;visibility: visible;bottom: 0;}
.team-member-container:hover .member-icons li:nth-child(1){-webkit-transition: 0.3s;-moz-transition: 0.3s;-o-transition: 0.3s;transition: 0.3s;}
.team-member-container:hover .member-icons li:nth-child(2){-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;transition: 0.5s;}
.team-member-container:hover .member-icons li:nth-child(3){-webkit-transition: 0.6s;-moz-transition: 0.6s;-o-transition: 0.6s;transition: 0.6s;}

/* Team Style 02 */
.team-member{border: 10px solid #fff;}
.team-thumb {position: relative;overflow: hidden;}
.team-thumb:after {content: '';position: absolute;background: transparent;width: 100%;height: 100%;left: 0;top: 0;visibility: hidden;opacity: 0;}
.team-thumb:hover:after {opacity: 1;visibility: visible;background-color: rgba(0, 0, 0, 0.2);z-index: 0;}
.team-thumb img {width: 100%;}
.member-info {position: absolute;left: 0;bottom: -20%;width: 100%;color: #fff;padding: 16px 0;opacity: 0;visibility: hidden;-webkit-transition: all 0.2s ease-out .2s;-moz-transition: all 0.2s ease-out .2s;-o-transition: all 0.2s ease-out .2s;transition: all 0.2s ease-out .2s;}
.team-thumb:hover .member-info{bottom: 0;opacity: 1;visibility: visible;z-index: 1;}
.member-info h3 {color: #fff;font-size: 22px;line-height: 25px;margin-bottom: 0;}
.member-info .title{display: block;font-size: 14px;line-height: 25px;font-weight: 300;}
.member-info .social-link {margin-top: 25px;}
.member-info .social-link li a {display: block;width: 35px;height: 35px;font-size: 18px;line-height: 35px;background: #fff;color: #4facfe;text-align: center;border-radius: 50%;}
.member-info .social-link li a:hover {background: #212121;color: #fff;}
.member-info.gradient-bg-two .social-link li a{color: #ec77ab;}
.member-info.gradient-bg-three .social-link li a{color: #ca4976;}
.member-info.gradient-bg-four .social-link li a{color: #CE9FFC;}
.member-info.gradient-bg-seven .social-link li a{color: #f6d365;}
.member-info.gradient-bg-eight .social-link li a{color: #C6426E;}
.member-info.gradient-bg-nine .social-link li a{color: #00f38c;}
.member-info.gradient-bg-ten .social-link li a{color: #decba4;}

/* Blogs */
.blog-slider .post {margin: 20px 0;padding: 0 10px;}
.post .post-img, .service-style-01 .service-box .service-img {overflow: hidden;position: relative;z-index: 10;}
.post-info {background-color: #fff;transition: all 0.2s ease;}
.post .post-img img, .service-box .service-img img {-webkit-transition: all .5s ease;transition: all .5s ease;z-index: 0;-webkit-backface-visibility: hidden;}
.post:hover .post-img img, .service-box:hover .service-img img {-webkit-transform: scale(1.1);transform: scale(1.1);-moz-transform: scale(1.1);-o-transform: scale(1.1);}
.post-info h3 {font-size: 15px;margin-top: 0;margin-bottom: 0px;}
.post-info h6 {color: #4facfe;font-size: 10px;margin-top: 5px;text-transform: uppercase;}
.post-info a {color:#212121;}
.post-info a:hover {color:#4facfe;}
.post-info p {margin-top: 20px;color: #bdbdbd;font-size:12px;}
.post-info .extras-wrap {padding: 0px 14px 0px 0px;display: inline-block;font-size: 14px;color: #9e9e9e;}
.post-info .extras-wrap i {position: relative;top: 1px;padding: 0px 5px 0px 0px;}
a.readmore {letter-spacing: 2px;color: #212121;font-size: 12px;text-transform: uppercase;font-weight: 700;}
a:hover.readmore {color: #4facfe;}
a.readmore > span {display: inline-block;vertical-align: middle;}
.post-info hr {border-top: 1px solid #e0e0e0;width: 100%;height: 1px;}

/* Blog Style 01 */
.blog-style-01 .post{-webkit-box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);-moz-box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);
-webkit-transition: all 0.4s ease;-moz-transition: all 0.4s ease;-o-transition: all 0.4s ease;transition: all 0.4s ease;}
.blog-style-01 .post:hover{-webkit-transform: translateY(-10px);-moz-transform: translateY(-10px);-ms-transform: translateY(-10px);-o-transform: translateY(-10px);transform: translateY(-10px);
-webkit-box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);-moz-box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);}
.blog-style-01 .post:hover .post-img img{transform: scale(1);-webkit-transform: scale(1);-moz-transform: scale(1);-o-transform: scale(1);}
.widget{margin-bottom: 30px;}
.blog-masonry-item {margin-bottom: 25px;}
.widget-title{position: relative;text-transform: uppercase;margin-bottom: 45px;font-family: 'Raleway', sans-serif;}
.aside-title{margin-bottom: 25px;border-bottom: 1px solid #f5f7fa;padding-bottom: 10px;font-family: 'Raleway', sans-serif;font-weight: 600;}
.search-form input{background-color: #fff;color: #000;}


/* 10.6 Widget Recent Post */

.post-item{overflow: hidden;margin-bottom: 20px;}
.post-item:last-child{margin-bottom: 0;}
.post-item h4{margin:0;}
.post-item .post-thumb{float: left;}
.post-item .post-thumb img{width: 75px;height: 58px;}
.post-item .post-body{margin-left: 100px;}
.post-item .post-time{font-size: 12px;line-height: 1.2;margin-top: 7px;color:#999;}
.post-item .subtitle{line-height: 1.2;}

/* 10.5 Widget Search */

.search-form{position: relative;}
.search-field{border-color: #e9e9e9!important;background:#fff;}
.search-field:focus {border-color: #4facfe!important;}

/* Widget Categories */

.widget_categories ul li:first-child, .widget_archive ul li:first-child{margin-top: 0;}
.widget_categories ul li, .widget_archive ul li{margin-top: 20px;line-height: 25px;list-style: none;position: relative;}
.widget_archive ul li span{position: absolute;top: 0;right: 0;}
.widget_categories ul li a, .widget_archive ul li a{color: #888;font-weight:100;}
.widget_categories ul li a:hover, .widget_archive ul li a:hover{color:#4facfe;text-decoration: none;}

/* Widget Tags */

.post-tags a {display: inline-block;padding: 5px 11px;margin: 0 5px 15px 0;border: 1px solid #e0e0e0;font-size: 10px;color: #888;text-transform: uppercase;letter-spacing: 0.2px;}
.post-tags a:hover{background: #4facfe;border: 1px solid #4facfe;color:#fff;text-decoration: none;}


/* pagination */
.pagination {border-radius: 0; padding: 0; margin: 0}
.pagination ul { display: inline-block; display: inline;margin-left: 0; margin-bottom: 0; padding: 0; }
.pagination li { display: inline; }
.pagination a { float: left; padding: 0 18px; line-height: 40px; text-decoration: none; border: 1px solid #ddd; border-left-width: 0; background: #fff }
.pagination a:hover {background-color: #4facfe; color: #fff }
.pagination .active a {background-color: #eaeaea;}
.pagination .active a { color: #999999; cursor: default; }
.pagination .disabled span, .pagination .disabled a, .pagination .disabled a:hover { color: #999999; background-color: transparent; cursor: default; }
.pagination li:first-child a { border-left-width: 1px; }
.pagination-centered { text-align: center; }
.pagination-right {text-align: right;}
.blog-standard{margin-top:20px;}
.blog-standard blockquote{margin:40px 0;background-color:#fff;padding:30px;position:relative;border-left-color:#4facfe;font-size:inherit;line-height:1.7;color:#888;}
.post-controls{margin-top:30px;overflow:hidden;}
.post-share li{display:inline-block;padding-right:10px;margin:0;}
.post-controls .post-share{float:left;}
.post-controls .comments-info{float:right;}
.post-controls .post-share li a{color:#212121;}
.post-controls .post-share li a:hover{color:#4facfe;}
/* 10.3 Comments */
.comments-info a{color:#212121;}
.comments-info a:hover{color:#4facfe;text-decoration:none;}
.comment-respond{margin-top:70px;margin-bottom:80px;}
.comment-reply-title{font-size:16px;margin-bottom:20px;text-transform:uppercase;}
.recent-post-title{font-size:16px;margin-bottom:20px;text-transform:uppercase;}
.comment-box{margin-bottom:80px;}
.post-comment{margin:30px 0;}
li.post-comment{list-style:none;}
.comment-content{padding:20px;background-color:#fff;border:1px solid #f1f1f1;}
.post-comment .avatar{margin-right:22px;float:left;}
.post-comment .avatar img{width:74px;height:74px;border-radius:100px;}
.post-comment .post-body{overflow:hidden;}
.post-comment .comment-header{line-height:1;font-size:13px;padding-right:46px;margin-top:3px;margin-bottom:10px;}
.post-comment .author a{font-family: 'Raleway', sans-serif;font-size:16px;font-weight:400;color:#212121;}
.post-comment .comment-header .time-ago a{font-size:12px;color:#888;margin-left:6px;}
.post-comment .reply a{font-size:10px;color:#888;margin-right:6px;float:right;background-color:#ececec;padding:5px 10px;}
.post-comment .reply-comment{padding-left:90px;}
.post-comment .reply-comment .avatar img{width:50px;height:50px;border-radius:100px;}
.comment-form .form-control{height:55px;border:1px solid #f5f7fa;}
.comment-form textarea.form-control{height:auto;}

/* Footer */

.footer{font-size:16px;}
.footer-main{padding:60px 0 60px;background-color: #f5f5f5;position: relative;}
.footer .widget-title{position:relative;line-height:1.4;text-transform:uppercase;margin-bottom:20px;color:#212121;font-family:'Montserrat', sans-serif;margin-top:11px;font-size:14px;}
.footer .widget-text p{color:#9e9e9e;font-size:14px;}
.footer .widget-links{font-family:'Open Sans', sans-serif;}
.footer .widget-links li{margin-bottom:10px;list-style:none;color:#9e9e9e;font-size:14px;}
.footer .widget-links li i{color:#9e9e9e;padding-right:10px;}
.footer .widget-links a{color:#9e9e9e;}
.footer .widget-links a:hover{color:#4facfe;text-decoration:none;}
.footer-copyright{padding:35px 0;background-color:#f5f7fa;}
.copy-right{margin-top:8px;color:#616161;font-size:13px;}
.copy-right .icon{color:#4facfe;margin-right:5px;}
.footer-copyright ul.social-media{float:right;display:block;margin-bottom:0;margin-top:5px;}
.footer-copyright ul.social-media li{display:inline-block;margin-right:20px;}
.footer-copyright ul.social-media li:last-child{margin-right:0;}
.footer-copyright ul.social-media li a{font-size:20px;display:inline-block;color:#616161;}
.footer-copyright ul.social-media li a:hover{color:#4facfe;}
.popular-tags a{display:inline-block;padding:5px 11px;margin:0 5px 15px 0;border:1px solid #9e9e9e;color:#9e9e9e;background:transparent;font-size:14px;}
.popular-tags a:hover{background:#4facfe;border:1px solid #4facfe;color:#fff;text-decoration:none;}

/* Google Maps */

.map-section{position:relative;min-height:500px;}
.map-parallax{position:fixed;width:100%;height:45%;z-index:-3;}
#myMap, #map-style-2, #map-style-3{min-height:500px;position:absolute;top:0;right:0;bottom:0;left:0;z-index:10;}
#myMap.wide, #map-style-2.wide, #map-style-3.wide{height:500px;width:100%;position:relative!important;}
#mapcontent p{margin:0;font-size:16px;}
/* Go Top */
#return-to-top{position:fixed;bottom:20px;right:20px;background:rgba(0, 0, 0, 0.7);width:35px;height:35px;text-decoration:none;-webkit-border-radius:35px;-moz-border-radius:35px;border-radius:100px;display:none;z-index:99;}
#return-to-top i{color:#fff;margin:0;position:relative;font-size:15px;text-align:center;display:block;line-height:36px;}
#return-to-top:hover{background:rgba(0, 0, 0, 0.9);}
/* Sliders */
.default-slider, .home-fullslide{position:relative;height:768px;overflow:hidden;}
.default-slider .container{padding-top:60px;}
.default-slider.slick-dotted.slick-slider, .blog-grid-slider.slick-dotted.slick-slider{margin-bottom:0;}
.home-slider{height:100vh;padding:0;}
.home-slider .default-slider, .home-slider .slick-list, .home-slider .slide-img, .home-slider .hero-text-wrap{height:100vh;width:100%;}
.default-slider .slide{position:relative;height:768px;}
.default-slider .slick-dots, .service-slider-2 .slick-dots{bottom:40px;}
.default-slider .slick-dots li button, .portfolio-slider .slick-dots li button{font-size:0;width:40px;height:3px;padding:0px;cursor:pointer;border:0;outline:none;background:rgba(195, 195, 195, 0.5);}
section.dark-block .default-slider .slick-dots li button{background:rgba(33, 33, 33, 0.5);}
.parallax-img{position:absolute;top:0;left:0;width:100%;height:100%;}
.full-screen-bg{height:100vh;min-height:100vh;}
.hero-text-wrap{display:table;table-layout:fixed;height:100%;width:100%;position:relative;z-index:200;}
.light-slider .hero-text-wrap{background-color:rgba(0, 0, 0, 0);}
.shape-bg{position:relative;}
/* .shape-bg:after{position:absolute;top:0;left:0;content:'';background:url(../images/slide-shape.png) no-repeat;width:590px;height:590px;z-index:-1;transform:translate(-5%, -18%);-webkit-transform:translate(-5%, -18%);-moz-transform:translate(-5%, -18%);-o-transform:translate(-5%, -18%);} */
.inline-btn p{display:inline-block;text-transform:uppercase;font-size:12px;letter-spacing:0.1em;margin:0 10px;font-family:'Montserrat', sans-serif;font-weight:500;vertical-align:middle;}
.inline-btn i{font-size:20px;}
.video-box{position:relative;overflow:hidden;border-radius:10px;}
.video-box_overlay{position:absolute;left:0;top:0;width:100%;height:100%;}
.play-button{width:100px;height:100px;margin-left:auto;margin-right:auto;background-color:#fff;border-radius:100px;-webkit-transition:all .3s ease;transition:all .3s ease;position:absolute;line-height:100px;top:50%;left:50%;-webkit-transform:translateX(-50%) translateY(-50%);transform:translateX(-50%) translateY(-50%);}
.play-button:hover{-webkit-transform:translateX(-50%) translateY(-50%)scale(1.1);transform:translateX(-50%) translateY(-50%)scale(1.1);cursor: pointer;}
.play-button:before{content:'';border:1px solid;border-color:#fff;width:100px;height:100px;border-radius:50%;position:absolute;left:0;top:0;opacity:1;-webkit-animation:1s it-zoom linear infinite;animation:1s it-zoom linear infinite;}
.play-button:after{content:'';border:1px solid;border-color:#fff;width:100px;height:100px;border-radius:50%;position:absolute;left:0;top:0;opacity:1;-webkit-animation:1s it-zoom linear infinite;animation:1s it-zoom linear infinite;}
.play-button:before{-webkit-animation-delay:.5s;animation-delay:.5s;}
.play-button i{line-height:inherit;display:block;text-align:center;color:#212121;font-size:60px;}
.hero-video{position:fixed;top:0;left:0;width:100%;height:100%;z-index:-1;}
.hero-video video{position:absolute;max-width:none;}
.bg-flex.bg-flex-left{left:0;}
.bg-flex.bg-flex-right{right:0;}
.bg-flex{padding:0;position:absolute!important;top:0px;height:100%;overflow:hidden;z-index:100;}
.bg-flex-holder{position:absolute;top:0;width:100%;height:100%;}
.bg-flex-cover{background-size:cover;background-position:0 0;background-repeat:no-repeat;}
.bg-flex-right{position:absolute;padding:0;right:0;top:0;bottom:0;overflow:hidden;}
.col-about-right{padding-left:60px;}
.col-about-left{padding-right:60px;}
.row-flex{display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;}
.row-flex:before{content:none;}
.row-flex > [class*="col-"]{display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-align:stretch;-moz-box-align:stretch;-ms-flex-align:stretch;align-items:stretch;}
@media only screen and (max-width:767px){.row-flex > [class*="col-"]:not([class*="col-xs"]){width:100%;}
}
.row-flex > [class*="col-"] .col-inner{display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-moz-box-flex:1;-ms-flex-positive:1;flex-grow:1;-webkit-box-orient:vertical;-webkit-box-direction:normal;-moz-box-orient:vertical;-moz-box-direction:normal;-ms-flex-direction:column;flex-direction:column;margin-left:-15px;margin-right:-15px;}
.col-inner.spacer{padding:100px 50px;}
.col-inner.spacer-md{padding:90px 50px;}
.col-inner.spacer-sm{padding:80px 50px;}
.col-inner.spacer-xs{padding:60px 50px;}
.row-flex.flex-center > [class*="col-"]{-webkit-box-align:center;-moz-box-align:center;-ms-flex-align:center;align-items:center;}
@media only screen and (min-width:768px){.row-flex.full-width-flex > [class*="col-"]{padding-left:6.5%;padding-right:6.5%;}
}
.row-flex.padding-row > [class*="col-"]{padding:7.5% 6.5%;}
.row-flex.flex-horizontal > [class*="col-"]:last-child{-webkit-box-pack:end;-moz-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;}
.row-flex.flex-spacing .column-inner{margin-left:0;margin-right:0;}
.social-networks{padding:0;list-style:none;}
.social-networks > li{display:inline-block;margin-right:12px;}
.social-networks > li > a{display:block;width:50px;height:50px;line-height:47px;text-align:center;font-size:16px;border:1px solid #e8e8e8;color:#212121;border-radius:100%;}
.social-networks > li > a:hover{color:#fff;}
.social-networks > li.social-icon-fb > a:hover{background-color:#3b5998;border-color:#3b5998;}
.social-networks > li.social-icon-tw > a:hover{background-color:#55acee;border-color:#55acee;}
.social-networks > li.social-icon-lk > a:hover{background-color:#0077b5;border-color:#0077b5;}
.social-networks > li.social-icon-be > a:hover{background-color:#053eff;border-color:#053eff;}
.social-networks > li.social-icon-de > a:hover{background-color:#ea4c89;border-color:#ea4c89;}
.social-networks > li.social-icon-sk > a:hover{background-color:#00aff0;border-color:#00aff0;}
.social-networks > li > a i{font-size:20px;}

/* Testimonials Style 01 */

.testimonial-item:focus {outline: none;}
.testimonial-item .testimonial-content {background-color: #f5f7fa;margin: 50px auto;text-align: center;}
.testimonial-item .testimonial-content img{margin-left: auto;margin-right: auto;}
.testimonial .slick-prev{left: -80px;}
.testimonial .slick-next{right: -80px;}

.testimonial-transparent .testimonial-item .testimonial-content{background-color: transparent;}

.testimonial .owl-theme .owl-nav{position: absolute;top:50%;width: 100%;}
.testimonial .owl-theme .owl-nav .owl-prev, .owl-theme .owl-nav .owl-next{position: absolute;}
.testimonial .owl-theme .owl-nav .owl-prev{left: -5%;}
.testimonial .owl-theme .owl-nav .owl-next{right: -5%;}
.testimonial .owl-theme .owl-nav [class*=owl-]{color: #212121;background-color: transparent;font-size: 35px;opacity: 0.5;}
.testimonial .owl-theme .owl-nav [class*=owl-]:hover{opacity: 1;}
.testimonial .owl-theme .owl-dots .owl-dot span{width: 12px;height: 6px;background: rgba(0, 0, 0, 0.1);border-radius: 100px;}
.testimonial .owl-theme .owl-dots .owl-dot.active span{width: 40px;background: rgba(79, 172, 254, 1);background: -moz-linear-gradient(45deg, rgba(79, 172, 254, 1) 0%, rgba(0, 242, 254, 1) 100%);background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(252, 103, 103, 1)), color-stop(100%, rgba(236, 0, 140, 1)));background: -webkit-linear-gradient(45deg, rgba(79, 172, 254, 1) 0%, rgba(0, 242, 254, 1) 100%);background: -o-linear-gradient(45deg, rgba(79, 172, 254, 1) 0%, rgba(0, 242, 254, 1) 100%);background: -ms-linear-gradient(45deg, rgba(79, 172, 254, 1) 0%, rgba(0, 242, 254, 1) 100%);background: linear-gradient(45deg, rgba(79, 172, 254, 1) 0%, rgba(0, 242, 254, 1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#00f2fe', GradientType=1 );}

/* Testimonials Style 02 */

.testimonial-style-2 .testimonial-item .testimonial-content {background-color: transparent;box-shadow: none;margin: 0 auto;}
.testimonial-style-2 .testimonial-item .testimonial-content img{width: 120px;margin-left: auto;margin-right: auto;}

/* Arrows */

.slick-prev, .slick-next{font-size: 0;line-height: 0;position: absolute;top: 50%;display: block;width: 20px;height: 20px;padding: 0;-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);transform: translate(0, -50%);cursor: pointer;
color: transparent;border: none;outline: none;background: transparent;z-index:10;}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus{color: #fff;outline: none;background: transparent;}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before{opacity: 1;}
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before{opacity: .25;}
.testimonial .slick-prev:before, .testimonial .slick-next:before{font-family:"Ionicons";font-size: 35px;line-height: 1;opacity: 0.5;color: #212121;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
.testimonial .slick-prev:hover:before, .testimonial .slick-prev:focus:before, .testimonial .slick-next:hover:before, .testimonial .slick-next:focus:before{opacity: 1;}
.slick-prev{left:25px;}
.testimonial .slick-prev:before{content:'\f2ca';}
.slick-next{right:25px;}
.testimonial .slick-next:before{content:'\f30f';}

/* Dots */

.slick-dotted.slick-slider{margin-bottom: 30px;}
.slick-dots{position: absolute;bottom: -25px;display: block;width: 100%;padding: 0;margin: 0;list-style: none;text-align: center;}
.slick-dots li {display: inline-block;margin: 0 4px;}
.slick-slide:focus{outline: none;}
.testimonial .slick-dots li button, .blog-slider .slick-dots li button, .testimonial-style-2 .slick-dots li button, .text-content-slider .slick-dots li button {font-size: 0;width: 12px;height: 6px;padding: 0px;cursor: pointer;border: 0;outline: none;
background: rgba(0, 0, 0, 0.1);border-radius: 100px;-webkit-border-radius: 100px;-moz-border-radius: 100px;}
.text-content-slider .owl-theme .owl-dots .owl-dot.active span{background-color: #fff;}
.testimonial .owl-theme .owl-dots .owl-dot.active span{width:40px;border-radius:100px;-webkit-border-radius:100px;-moz-border-radius:100px;height:6px;background:rgba(79,172,254,1);background:-moz-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);background:-webkit-gradient(left bottom, right top, color-stop(0%, rgba(252,103,103,1)), color-stop(100%, rgba(236,0,140,1)));background:-webkit-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);background:-o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);background:-ms-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);background:linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(0,242,254,1) 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#4facfe', endColorstr='#00f2fe', GradientType=1 );}
.testimonial.gradient-bullet-two .owl-theme .owl-dots .owl-dot.active span{background:rgba(236,119,171);background:-moz-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);background:-webkit-gradient(left bottom, right top, color-stop(0%, rgba(236,119,171)), color-stop(100%, rgba(115,75,109,1)));background:-webkit-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);background:-o-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);background:-ms-linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);background:linear-gradient(45deg, rgba(236,119,171) 0%, rgba(120,115,245) 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec77ab', endColorstr='#734b6d', GradientType=1 );}
.testimonial.gradient-bullet-three .owl-theme .owl-dots .owl-dot.active span{background:rgba(202,73,118,1);background:-moz-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);background:-webkit-gradient(left bottom, right top, color-stop(0%, rgba(202,73,118,1)), color-stop(100%, rgba(255,84,84,1)));background:-webkit-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);background:-o-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);background:-ms-linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);background:linear-gradient(45deg, rgba(202,73,118,1) 0%, rgba(255,84,84,1) 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#ca4976', endColorstr='#ff5454', GradientType=1 );}
.testimonial.gradient-bullet-four .owl-theme .owl-dots .owl-dot.active span{background:#9357cc;background:-webkit-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);background:-moz-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);background:-o-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);background:-ms-linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);background:linear-gradient(left, #9357cc 0%,#2989d8 50%,#2cc99d 100%);}
.testimonial.gradient-bullet-five .owl-theme .owl-dots .owl-dot.active span{background:rgba(246,211,101,1);background:-moz-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);background:-webkit-gradient(left bottom, right top, color-stop(0%, rgba(246,211,101,1)), color-stop(100%, rgba(253,160,133,1)));background:-webkit-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);background:-o-linear-gradient(45deg, rgba(79,172,254,1) 0%, rgba(253,242,254,1) 100%);background:-ms-linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);background:linear-gradient(45deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6d365', endColorstr='#fda085', GradientType=1 );}
.testimonial.gradient-bullet-six .owl-theme .owl-dots .owl-dot.active span{background: rgba(247,157,0,1);background: -moz-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(10,243,140,1) 100%);
background: -webkit-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);background: -o-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);background: -ms-linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);
background: linear-gradient(45deg, rgba(247,157,0,1) 0%, rgba(0,243,140,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f79d00', endColorstr='#64f38c', GradientType=1 );}
.testimonial-style-2 .slick-dots li.slick-active button, .app-gallery-slider .slick-dots li.slick-active button{background-color: #fff;}
.service-slider-2 .slick-prev:before, .blog-grid-slider .slick-prev:before, .default-slider .slick-prev:before {content: '\eb76';}
.service-slider-2 .slick-next:before, .blog-grid-slider .slick-next:before, .default-slider .slick-next:before {content: '\eb79';}
.service-slider-2 .slick-prev:before, .service-slider-2 .slick-next:before, .blog-grid-slider .slick-prev:before, .blog-grid-slider .slick-next:before, #product-slider .slick-prev:before, #product-slider .slick-next:before, section.parallax-bg .testimonial-style-2 .slick-prev:before, section.parallax-bg .testimonial-style-2 .slick-next:before,
 section.dark-block .default-slider .slick-prev:before, section.dark-block .default-slider .slick-next:before {font-family: 'icofont';font-size: 25px;
line-height: 1;opacity: 1;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
.service-slider-2 .slick-prev, .service-slider-2 .slick-next, .blog-grid-slider .slick-prev, .blog-grid-slider .slick-next {border: 2px solid rgba(255,255,255,0.7);width: 50px;height: 50px;color:#fff;border-radius: 100px;}
.blog-grid-slider .slick-prev, .blog-grid-slider .slick-next {border-radius: 0px;}
.service-slider-2 .slide{position: relative;}
.service-slider-2, .service-slider-2 .slick-list{width: 100%;}
.no-direction .slick-prev, .no-direction .slick-next{display: none!important;}
.service-slider-2 .slick-prev:hover, .service-slider-2 .slick-next:hover, .blog-grid-slider .slick-prev:hover, .blog-grid-slider .slick-next:hover{color: #212121;background: #fff;}
.service-slider-2 .slick-prev:focus, .default-slider .slick-next:focus  {outline: none;}
.default-slider .slick-dots li.slick-active button {background-color: #fff;}
section.dark-block .default-slider .slick-dots li.slick-active button, .portfolio-slider .slick-dots li.slick-active button {background-color:#212121;}
section.dark-block .default-slider .slick-prev, section.dark-block .default-slider .slick-next {border: 1px solid rgba(33,33,33,0.7);color:#212121;width: 50px;height: 50px;border-radius: 100px;}
section.dark-block .default-slider .slick-prev:hover, section.dark-block .default-slider .slick-next:hover {color:#fff;background: #212121;}

/*	Simple Slider   */
.simple-content-slider{width: 100%; height: 100%; position: absolute; right: 0; left: 0;}
.simple-content-slider-text{display: table; height: 100%; width: 100%;}
.simple-content-text-inner{display: table-cell; vertical-align: middle;}

/*	Template Social Icons   */

.member-socialicons{overflow: hidden;list-style: none;text-align:center;line-height: 20px;}
.member-socialicons li{float: left;padding: 0 10px;line-height: inherit;list-style-type: none;}
.member-socialicons li a{color: #fff;display:block;}
.member-socialicons li a:hover {color: #ff4552;}
.member-socialicons li a i{display:block;font-size:13px;text-align:center;}
ul.social-default, ul.social-default-no-border{display:block;}
ul.social-default li, ul.social-default-no-border li {display:inline-block;margin-right: 12px;}
ul.social-default li:last-child, ul.social-default-no-border li:last-child {margin-right:0;}
ul.social-default li a{font-size: 16px;display: block;border: solid 1px;text-align: center;width: 40px;height: 40px;line-height: 40px;border-radius: 100px;}
ul.social-default li a.fb{border-color:#3C599F;color:#3C599F;}
ul.social-default li a:hover.fb, ul.social-default li a:focus.fb{border-color:#3C599F;color:#fff;background-color: #3c599f;}
ul.social-default li a.tw{border-color:#00aced;color:#00aced;}
ul.social-default li a:hover.tw, ul.social-default li a:focus.tw{border-color:#00aced;color:#fff;background-color: #00aced;}
ul.social-default li a.dr{border-color:#ea4c89;color:#ea4c89;}
ul.social-default li a:hover.dr, ul.social-default li a:focus.dr{border-color:#ea4c89;color:#fff;background-color: #ea4c89;}
ul.social-default li a.be{border-color:#1769FF;color:#1769FF;}
ul.social-default li a:hover.be, ul.social-default li a:focus.be{border-color:#1769FF;color:#fff;background-color: #1769FF;}
ul.social-default li a.ev{border-color:#528036;color:#528036;}
ul.social-default li a:hover.ev, ul.social-default li a:focus.ev{border-color:#528036;color:#fff;background-color: #528036;}

ul.social-default-no-border{margin-left:auto;margin-right:auto;text-align: center;}
ul.social-default-no-border li a{font-size:16px;display:inline-block;color:#212121;}
ul.social-default-no-border.big li a{font-size: 26px;color:#fff;}
ul.social-default-no-border li a:hover.facebook, ul.social-default-no-border li a:focus.facebook{color:#3C599F;}
ul.social-default-no-border li a:hover.twitter, ul.social-default-no-border li a:focus.twitter{color:#00aced;}
ul.social-default-no-border li a:hover.behance, ul.social-default-no-border li a:focus.behance{color:#1769FF;}
ul.social-default-no-border li a:hover.dribble, ul.social-default-no-border li a:focus.dribble{color:#ea4c89;}
ul.social-default-no-border li a:hover.google-plus, ul.social-default-no-border li a:focus.google-plus{color:#dd4b39;}
ul.social-default-no-border li a:hover.linkedin, ul.social-default-no-border li a:focus.linkedin{color:#007bb6;}
ul.social-default-no-border li a:hover.youtube, ul.social-default-no-border li a:focus.youtube{color:#bb0000;}
ul.social-default-no-border li a:hover.instagram, ul.social-default-no-border li a:focus.instagram{color:#517fa4;}
ul.social-default-no-border li a:hover.pinterest, ul.social-default-no-border li a:focus.pinterest{color:#cb2027;}
ul.social-default-no-border li a:hover.flicker, ul.social-default-no-border li a:focus.flicker{color:#ff0084;}
ul.social-default-no-border li a:hover.tumblr, ul.social-default-no-border li a:focus.tumblr{color:#32506d;}
ul.social-default-no-border li a:hover.github, ul.social-default-no-border li a:focus.github{color:#171516;}

.xs-icon, .sm-icon, .md-icon, .lg-icon, .xl-icon {list-style: none; padding: 0;}
.xs-icon li, .sm-icon li, .md-icon li, .lg-icon li, .xl-icon li {display: inline-block;}
footer .xs-icon li:first-child, footer .sm-icon li:first-child, footer .md-icon li:first-child, footer .lg-icon li:first-child, footer .xl-icon li:first-child {margin-left: 0;}
footer .xs-icon li:last-child, footer .sm-icon li:last-child, footer .md-icon li:last-child, footer .lg-icon li:last-child, footer .xl-icon li:last-child {margin-right: 0;}
.xs-icon li {margin: 0 2px}
.sm-icon li {margin: 0 5px}
.md-icon li {margin: 0 8px}
.lg-icon li {margin: 0 10px}
.xl-icon li {margin: 0 12px}
.xs-icon a {font-size: 14px; width:30px!important; height:30px!important;line-height:29px!important;}
.sm-icon a {font-size: 16px; width: 40px!important; height:40px!important;line-height: 38px!important;}
.md-icon a {font-size: 20px; width: 50px; height:50px;line-height: 48px;}
.lg-icon a {font-size: 24px; width: 60px!important; height: 60px!important;line-height: 61px!important;}
.xl-icon a {font-size: 30px; width: 70px!important; height: 70px!important;line-height: 68px!important;}
.md-icon.left-icon li {margin-right: 10px;margin-left: 0;}

/*	Elememts Social Icons   */

/*	Social Icons Style 01   */

.social-icons-style-01 a, .social-icons-style-07 a {text-align: center; display:inline-block; transition: all 400ms; -webkit-transition: all 400ms; -moz-transition: all 400ms; -ms-transition: all 400ms; -o-transition: all 400ms; color: #fff}
.social-icons-style-01 a:hover {border-radius: 100%;}
.social-icons-style-01 a.fb, .social-icons-style-02 a.fb:hover, .social-icons-style-03 a.fb span, .social-icons-style-06 .icon.facebook:before, .social-icons-style-09 a.facebook, .social-icons-style-10 a.fb span, .social-icons-style-11 .facebook .back{background-color: #3b5998;}
.social-icons-style-01 a.tw, .social-icons-style-02 a.tw:hover, .social-icons-style-03 a.tw span, .social-icons-style-06 .icon.twitter:before, .social-icons-style-09 a.twitter, .social-icons-style-10 a.tw span, .social-icons-style-11 .twitter .back{background-color: #00aced;}
.social-icons-style-01 a.be, .social-icons-style-02 a.be:hover, .social-icons-style-03 a.be span, .social-icons-style-06 .icon.behance:before, .social-icons-style-09 a.behance, .social-icons-style-10 a.be span, .social-icons-style-11 .behance .back{background-color: #1769FF;}
.social-icons-style-01 a.in, .social-icons-style-02 a.in:hover, .social-icons-style-03 a.in span, .social-icons-style-06 .icon.linkedin:before, .social-icons-style-09 a.linkedin, .social-icons-style-10 a.in span, .social-icons-style-11 .linkedin .back{background-color: #007bb6;}
.social-icons-style-01 a.yt, .social-icons-style-02 a.yt:hover, .social-icons-style-03 a.yt span, .social-icons-style-06 .icon.youtube:before, .social-icons-style-09 a.youtube, .social-icons-style-10 a.yt span, .social-icons-style-11 .youtube .back{background-color: #bb0000;}
.social-icons-style-01 a.ins, .social-icons-style-02 a.ins:hover, .social-icons-style-03 a.ins span, .social-icons-style-06 .icon.instagram:before, .social-icons-style-09 a.instagram, .social-icons-style-10 a.ins span, .social-icons-style-11 .instagram .back{background-color: #fe1f49;}
.social-icons-style-01 a.tb, .social-icons-style-02 a.tb:hover, .social-icons-style-03 a.tb span, .social-icons-style-06 .icon.tumblr:before, .social-icons-style-09 a.tumblr, .social-icons-style-10 a.tb span, .social-icons-style-11 .tumblr .back{background-color: #32506d;}
.social-icons-style-01 a.fk, .social-icons-style-02 a.fk:hover, .social-icons-style-03 a.fk span, .social-icons-style-06 .icon.flicker:before, .social-icons-style-09 a.flickr, .social-icons-style-10 a.fk span, .social-icons-style-11 .flickr .back{background-color: #ff0084;}
.social-icons-style-01 a.rss, .social-icons-style-02 a.rss:hover, .social-icons-style-03 a.rss span, .social-icons-style-06 .icon.rss:before, .social-icons-style-09 a.rss, .social-icons-style-10 a.rss span, .social-icons-style-11 .rss .back{background-color: #f2890a;}
.social-icons-style-01 a.pin, .social-icons-style-02 a.pin:hover, .social-icons-style-03 a.pin span, .social-icons-style-06 .icon.pinterest:before, .social-icons-style-09 a.pinterest, .social-icons-style-10 a.pin span, .social-icons-style-11 .pinterest .back{background-color: #cb2027;}
.social-icons-style-01 a.git, .social-icons-style-02 a.git:hover, .social-icons-style-03 a.git span, .social-icons-style-06 .icon.github:before, .social-icons-style-09 a.github, .social-icons-style-10 a.git span, .social-icons-style-11 .github .back{background-color: #171516;}
.social-icons-style-01 a.gplus, .social-icons-style-02 a.gplus:hover, .social-icons-style-03 a.gplus span, .social-icons-style-06 .icon.google-plus:before, .social-icons-style-09 a.google-plus, .social-icons-style-10 a.gplus span, .social-icons-style-11 .google-plus .back{background-color: #dd4b39;}
.social-icons-style-01 a.dr, .social-icons-style-02 a.dr:hover, .social-icons-style-03 a.dr span, .social-icons-style-06 .icon.dribble:before, .social-icons-style-09 a.dribble, .social-icons-style-10 a.dr span, .social-icons-style-11 .dribble .back{background-color: #ea4c89;}

/*	Social Icons Style 02   */

.social-icons-style-02 a {text-align: center; display:inline-block; color: #fff}
.social-icons-style-02 a:hover {color: #fff; border-radius: 100%;}

/*	Social Icons Style 03   */

.social-icons-style-03 {position: relative;}
.social-icons-style-03 a {text-align: center; display:inline-block; border-radius: 100%; position: relative; text-align: center}
.social-icons-style-03 a:hover {color: #fff;}
.social-icons-style-03 a i {position: relative; z-index: 1;}
.social-icons-style-03 a span {border-radius: 0; display: block; height: 0; left: 50%; margin: 0; position: absolute; top: 50%; transition: all 0.3s; -webkit-transition: all 0.3s; -moz-transition: all 0.3s; -ms-transition: all 0.3s; -o-transition: all 0.3s; width: 0;}
.social-icons-style-03 a:hover span{width:100%; height:100%; border-radius: 100%; top: 0; left: 0;}

/*	Social Icons Style 04   */

.social-icons-style-04 a:hover.facebook{color:#3C599F;}
.social-icons-style-04 a:hover.twitter{color:#00aced;}
.social-icons-style-04 a:hover.behance{color:#1769FF;}
.social-icons-style-04 a:hover.dribble{color:#ea4c89;}
.social-icons-style-04 a:hover.google-plus{color:#dd4b39;}
.social-icons-style-04 a:hover.linkedin{color:#007bb6;}
.social-icons-style-04 a:hover.youtube{color:#bb0000;}
.social-icons-style-04 a:hover.instagram{color:#517fa4;}
.social-icons-style-04 a:hover.pinterest{color:#cb2027;}
.social-icons-style-04 a:hover.flicker{color:#ff0084;}
.social-icons-style-04 a:hover.tumblr{color:#32506d;}
.social-icons-style-04 a:hover.github{color:#171516;}
.social-icons-style-04 a:hover.rss{color:#f2890a;}

/*	Social Icons Style 05   */

.social-icons-style-05 a.facebook, .social-icons-style-06 .icon.facebook .icofont, .social-icons-style-12 a.facebook{color:#3C599F;}
.social-icons-style-05 a.twitter, .social-icons-style-06 .icon.twitter .icofont, .social-icons-style-12 a.twitter{color:#00aced;}
.social-icons-style-05 a.behance, .social-icons-style-06 .icon.behance .icofont, .social-icons-style-12 a.behance{color:#1769FF;}
.social-icons-style-05 a.dribble, .social-icons-style-06 .icon.dribble .icofont, .social-icons-style-12 a.dribble{color:#ea4c89;}
.social-icons-style-05 a.google-plus, .social-icons-style-06 .icon.google-plus .icofont, .social-icons-style-12 a.google-plus{color:#dd4b39;}
.social-icons-style-05 a.linkedin, .social-icons-style-06 .icon.linkedin .icofont, .social-icons-style-12 a.linkedin{color:#007bb6;}
.social-icons-style-05 a.youtube, .social-icons-style-06 .icon.youtube .icofont, .social-icons-style-12 a.youtube{color:#bb0000;}
.social-icons-style-05 a.instagram, .social-icons-style-06 .icon.instagram .icofont, .social-icons-style-12 a.instagram{color:#517fa4;}
.social-icons-style-05 a.pinterest, .social-icons-style-06 .icon.pinterest .icofont, .social-icons-style-12 a.pinterest{color:#cb2027;}
.social-icons-style-05 a.flicker, .social-icons-style-06 .icon.flicker .icofont, .social-icons-style-12 a.flicker{color:#ff0084;}
.social-icons-style-05 a.tumblr, .social-icons-style-06 .icon.tumblr .icofont, .social-icons-style-12 a.tumblr{color:#32506d;}
.social-icons-style-05 a.github, .social-icons-style-06 .icon.github .icofont, .social-icons-style-12 a.github{color:#171516;}
.social-icons-style-05 a.rss, .social-icons-style-06 .icon.rss .icofont, .social-icons-style-12 a.rss{color:#f2890a;}
.social-icons-style-05 a:hover{color:#fff;}

/*	Social Icons Style 06   */

.social-icons-style-06 .icon {display: inline-block;background-color: #fff;width: 50px;height: 50px;line-height: 50px;margin: 0;text-align: center;position: relative;overflow: hidden;border-radius: 28%;box-shadow: 0 5px 15px -5px rgba(0,0,0,0.1);opacity: 0.99;}
.social-icons-style-06 .icon, .social-icons-style-06 .icon:before, .social-icons-style-06 .icon .icofont {transition: all 0.35s;transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);}
.social-icons-style-06 .icon:before {content: '';width: 120%;height: 120%;position: absolute;-webkit-transform: rotate(45deg);transform: rotate(45deg);}
.social-icons-style-06 .icon:before {top: 90%;left: -110%;}
.social-icons-style-06 .icon .icofont {vertical-align: middle;}
.social-icons-style-06 .icon .icofont {-webkit-transform: scale(0.8);transform: scale(0.8);}
.social-icons-style-06 .icon:focus:before, .social-icons-style-06 .icon:hover:before {top: -10%;left: -10%;}
.social-icons-style-06 .icon:focus .icofont, .social-icons-style-06 .icon:hover .icofont {color: #fff;-webkit-transform: scale(1);transform: scale(1);position: relative;}

/*	Social Icons Style 07   */

.social-icons-style-07 a:hover{color:#4facfe;}

/*	Social Icons Style 08   */

.social-icons-style-08 a {text-align: center; display:inline-block;border: 1px solid rgba(0,0,0,0.1);color: #232323; background-color: transparent; border-radius: 100%;}
.social-icons-style-08 a:hover {background-color: transparent;}
.social-icons-style-08 a.facebook:hover {border-color: #3b5998;color: #3b5998;}
.social-icons-style-08 a.twitter:hover {border-color: #00aced; color: #00aced}
.social-icons-style-08 a.google-plus:hover {border-color: #dc4a38; color: #dc4a38}
.social-icons-style-08 a.dribble:hover {border-color: #ea4c89; color: #ea4c89}
.social-icons-style-08 a.youtube:hover {border-color: #bb0000; color: #bb0000}
.social-icons-style-08 a.linkedin:hover {border-color: #0077b5; color: #0077b5}
.social-icons-style-08 a.instagram:hover {border-color: #FE1F49; color: #FE1F49}
.social-icons-style-08 a.pinterest:hover {border-color: #CB2027; color: #CB2027}
.social-icons-style-08 a.flickr:hover {border-color: #ff0084; color: #ff0084}
.social-icons-style-08 a.rss:hover {border-color: #f2890a; color: #f2890a}
.social-icons-style-08 a.behance:hover {border-color: #1769ff; color: #1769ff}
.social-icons-style-08 a.tumblr:hover {border-color: #32506d; color: #32506d}

/*	Social Icons Style 09   */

.social-icons-style-09 a {text-align: center; display:inline-block;-webkit-transition: -webkit-transform 1s, backgrond 0.4s;-moz-transition: -moz-transform 1s, background 0.4s;-o-transition: -o-transform 1s, background 0.4s;transition: transform 1s, background 0.4s; border-radius: 100%; color: #fff}
.social-icons-style-09 a:hover{-webkit-transform: translateY(-10px)rotateZ(360deg);-moz-transform: translateY(-10px)rotateZ(360deg);-ms-transform: translateY(-10px)rotateZ(360deg);-o-transform: translateY(-10px)rotateZ(360deg);transform: translateY(-10px)rotateZ(360deg);}

/*	Social Icons Style 10   */

.social-icons-style-10 a {text-align: center; display:inline-block; border-radius: 100%; position: relative; border: 1px solid rgba(0, 0, 0, 0.1); color: #232323; text-align: center; z-index: 1;}
.social-icons-style-10 a:hover {color: #fff;}
.social-icons-style-10 a span {border-radius: 0; display: block; height: 0; left: 50%; margin: 0; position: absolute; top: 50%; transition: all 0.3s; -webkit-transition: all 0.3s; -moz-transition: all 0.3s; -ms-transition: all 0.3s; -o-transition: all 0.3s; width: 0;z-index: -1;}
.social-icons-style-10 a:hover span{width:100%; height:100%; border-radius: 100%; top: 0; left: 0;}

/*	Social Icons Style 11   */

.social-icons-style-11 a {background: #fff;color: #212121;float: left;-webkit-border-radius: 100%;-moz-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;border-radius: 100%;display: inline-block;;position: relative;-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;transition: 0.5s;}
.social-icons-style-11 .front, .social-icons-style-11 .back {width: 50px;height: 50px;position: absolute;top: 0;left: 0;-webkit-border-radius: 100%;-moz-border-radius: 100%;-ms-border-radius: 100%;-o-border-radius: 100%;border-radius: 100%;-webkit-backface-visibility: visible;-moz-backface-visibility: visible;
-ms-backface-visibility: visible;-o-backface-visibility: visible;backface-visibility: visible;}
.social-icons-style-11 .back {-webkit-transform: rotateY(-180deg);-moz-transform: rotateY(-180deg);-ms-transform: rotateY(-180deg);-o-transform: rotateY(-180deg);transform: rotateY(-180deg);-webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;-ms-backface-visibility: hidden;-o-backface-visibility: hidden;backface-visibility: hidden;}
.social-icons-style-11 a:hover .back {-webkit-backface-visibility: visible;-moz-backface-visibility: visible;-ms-backface-visibility: visible;-o-backface-visibility: visible;backface-visibility: visible;}
.social-icons-style-11 a:hover {color: #fff;-webkit-transform: rotateY(-180deg);-moz-transform: rotateY(-180deg);-ms-transform: rotateY(-180deg);-o-transform: rotateY(-180deg);transform: rotateY(-180deg);}

/*	Social Icons Style 12   */

.social-icons-style-12 a {color: #fff;background-color:#fff;border-radius: 5px;text-align: center;text-decoration: none;position: relative;display: inline-block;width: 50px;height: 50px;-o-transition: all .5s;-webkit-transition: all .5s;-moz-transition: all .5s;transition: all .5s;-webkit-font-smoothing: antialiased;}
.social-icons-style-12 a span {color: #212121;position: absolute;bottom: 0;left: -25px;right: -25px;z-index: -1;font-size: 14px;border-radius: 2px;background: #fff;visibility: hidden;opacity: 0;-o-transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);-webkit-transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);-moz-transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);}
.social-icons-style-12 a:hover span {bottom: 60px;visibility: visible;opacity: 1;}
.social-icons-style-12 a span:before {content: '';width: 0;height: 0;border-left: 5px solid transparent;border-right: 5px solid transparent;border-top: 5px solid #fff;position: absolute;bottom: -5px;left: 50%;transform: translateX(-50%);-webkit-transform: translateX(-50%);-moz-transform: translateX(-50%);}

/* Breadcrumb */

.breadcrumb {background: transparent; border-radius: 0; padding: 0;display: block;}
.breadcrumb ul { list-style: none; padding: 0; margin: 0;}
.breadcrumb ul li {display: inline-block;}
.breadcrumb ul li a{color:#fff;-webkit-transition: all .3s ease;-o-transition: all .3s ease;transition: all .3s ease;}
.breadcrumb ul li a:hover, .breadcrumb ul li a:focus{color:#4facfe;}
.breadcrumb ul > li:after {content: "\eb8b";font-family: icofont;padding: 0 11px 0 14px;display: inline-block;vertical-align: middle;font-size: 10px;color:#fff;}
.breadcrumb ul > li:last-child:after {border-right: 0; content: ""; padding: 0}

/* List Styles */

/* list Style 01 */
.list-style-none li {list-style: none;}

.list-style-01 li {padding: 5px 0}

/* list Style 02 */
.list-style-02 {list-style: none; margin: 0; padding: 0;}
.list-style-02 li {position: relative;margin: 0 0 15px 0;}
.list-style-02 li.not-available{color: #ccc;}
.list-style-02 li.not-available:before{color: #fe4f4f;content:"\efb8";font-family: icofont;margin-right: 10px;}
.list-style-02 li:before {content:"\efad";font-family: icofont;margin-right: 10px;color:#4facfe;}

/* list Style 03 */
.list-style-03 { list-style: none; margin: 0; padding: 0;}
.list-style-03 li { position: relative; padding: 0 0 0 22px; margin: 0 0 9px 0;}
.list-style-03 li:before { content: ""; width: 10px; height: 1px; background-color: #212121; position: absolute; top: 11px; left: 0; }

/* list Style 04 */
.list-style-04 { list-style: none; margin: 0; padding: 0;}
.list-style-04 li { padding: 0; margin: 0 0 10px 0;}
.list-style-04 li i{margin-right: 10px;}

/* Our Process */

/* Our Process Style 01 */

.our-process-style-01 .count-wrap {position: relative;width: 80px;height: 80px;margin: 0 auto 30px;}
.our-process-style-01 .count-wrap:before {width: 80px;height: 80px;opacity: .2;z-index: 1;}
.our-process-style-01 .count-wrap:after {width: 65px;height: 65px;opacity: .5;z-index: 2;}
.our-process-style-01 .count-wrap:after, .our-process-style-01 .count-wrap:before {position: absolute;top: 50%;left: 50%;content: '';-webkit-transform: translate(-50%,-50%);-ms-transform: translate(-50%,-50%);transform: translate(-50%,-50%);border-radius: 100px;}
.our-process-style-01 .count, .our-process-style-01 .count-wrap:after, .our-process-style-01 .count-wrap:before{background-color: #4facfe;}
.our-process-style-01 .count {position: absolute;top: 50%;left: 50%;width: 50px;height: 50px;border-radius: 100px;text-align: center;line-height: 50px;color: #fff;font-size: 24px;font-weight: 600;-webkit-transform: translate(-50%,-50%);-ms-transform: translate(-50%,-50%);transform: translate(-50%,-50%);z-index: 3;}
.our-process-style-01 .line:after, .our-process-style-01 .line:before {content: "";display: block;width: 50%;height: 1px;background: #f5f7fa;position: absolute;top: 40px;text-align: center;}
.our-process-style-01 .line:after{right:0}
.our-process-style-01 .line:before{left:0}
.our-process-style-01 .line:first-child:before{display: none;}
.our-process-style-01 .line:last-child:after{display: none;}

/* Our Process Style 02 */

.our-process-style-02 .icon-wrap {position: relative;width: 100px;height: 100px;margin: 0 auto 30px;border-radius: 100px;}
.our-process-style-02 .icon {position: absolute;top: 50%;left: 50%;width: 50px;height: 50px;text-align: center;line-height: 65px;-webkit-transform: translate(-50%,-50%);-ms-transform: translate(-50%,-50%);transform: translate(-50%,-50%);z-index: 3;margin-left: 0;}
.our-process-style-02 .line {position: relative;}
.our-process-style-02 .line:after{content: "";display: block;width: 100%;height: 1px;background: #fff;position: absolute;top: 55px;text-align: center;left:50%;}
.our-process-style-02 .line:last-child:after{display: none;}


/* Custom Styles */

.left-img {bottom: -85px;position: absolute;left: -70px;width: 54%;}
.left-img img {max-width: none;}

.client-logo {margin: 0px 15px;}
.client-logo img {margin: auto;opacity: 0.7;filter: alpha(opacity=70);}
.client-logo img:hover {opacity: 1.0;filter: alpha(opacity=100);}
section.big-block .client-logo {padding:10%;}
.client-sm-logos img{margin:0 auto;width:150px;}
.client-logo-height img {-webkit-filter: grayscale(1);filter: grayscale(1);}
.client-logo-height:hover img {filter: grayscale(0);-webkit-filter: grayscale(0);}
.client-logo-height{height:190px;}
.client-logo-height:hover{box-shadow: 0 0 4px rgba(0,0,0,.1);}
.default-slider .slide:focus, .testimonial-item:focus, .quote-item:focus, .client-logo:focus, .zoom-text:focus, .portfolio-slider .item:focus, .blog-grid-slider .item:focus, .member:focus, .blog-slider .post:focus, #product-slide .small-thumb:focus, #product-slider .big-thumb:focus, .model-slider .item-model:focus {outline: none;}
.cover-bg{position: relative !important;background-size: cover !important;overflow: hidden !important;background-position: center !important;background-repeat: no-repeat !important;}
.title-hero-bg {background-size: cover;-webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;background-repeat:no-repeat;background-position:center center;position: relative;min-height: 400px;}
.title-hero-bg::before {content: "";background-color: rgba(0, 0, 0, 0.6);width: 100%;height: 100%;position: absolute;top: 0;left: 0;}
.page-title{position:absolute;top:40%;width:100%;left:0;z-index:15;-webkit-transform:translate(0%, 0%);-moz-transform:translate(0%, 0%);-ms-transform:translate(0%, 0%);-o-transform:translate(0%, 0%);transform:translate(0%, 0%);}
.page-title h1{font-size:50px;line-height:60px;margin:0;}
.grid-box [class^=col-]{padding-top:15px;padding-bottom:15px;background-color:rgba(255, 255, 255,.05);border:1px solid rgba(0,0,0,.09);margin-top:5px;margin-bottom:5px;}

/* Countdowns */

.countdown {margin-bottom: 0;}
.countdown li {display: inline-block;text-align: center;margin-right:50px;}
.countdown li:last-child {margin-right:0;}

/* Skills Bar */

.skillbar-style-01 .skillbar {position:relative;display:inline-block;margin:40px 0 0px;width:100%;height:25px;width:100%;background-color: #f5f7fa;}
.skillbar-style-01 .skillbar-title {position:absolute;top:-25px;left:0; }
.skillbar-style-01 .skillbar-bar {height:25px;width:0px;display:inline-block;margin-bottom: 0;}
.skillbar-style-01 .skill-bar-percent {position:absolute;right:-40px;top:-8px;}

.skillbar-style-02 .skillbar {position:relative;display:inline-block;margin:40px 0 10px;width:100%;height:25px;width:100%;background-color: #212121;}
.skillbar-style-02 .skillbar-title {position:absolute;top:-25px;left:0; }
.skillbar-style-02 .skillbar-bar {height:25px;width:0px;display:inline-block;}
.skillbar-style-02 .skill-bar-percent {position:absolute;right:0px;top:-25px;}

.skillbar-style-03 .skillbar {position:relative;display:inline-block;margin:40px 0 10px;width:100%;height:25px;width:100%;background-color: #f5f7fa;}
.skillbar-style-03 .skillbar-title {position:absolute;top:10px;left:0; }
.skillbar-style-03 .skillbar-bar {height:25px;width:0px;display:inline-block;}
.skillbar-style-03 .skill-bar-percent {position:absolute;right:0px;top:10px;}

/* Pie Charts */

.chart-style-01 .chart-circle {margin: 0 0 20px 0;}
.chart-style-01 .chart-01 {position: relative;display: inline-block;width: 150px;height: 150px;text-align: center;}
.chart-style-01 .chart-01 canvas {position: absolute;top: 0;left: 0;}
.chart-style-01 .percent {display: inline-block;line-height: 150px;z-index: 2;}
.chart-style-01 .percent:after {content: '%';}

.chart-style-02 .chart-circle {margin: 0 0 20px 0;}
.chart-style-02 .chart-02 {position: relative;display: inline-block;width: 120px;height: 120px;text-align: center;}
.chart-style-02 .chart-02 canvas {position: absolute;top: 0;left: 0;}
.chart-style-02 .percent {display: inline-block;line-height: 120px;z-index: 2;}
.chart-style-02 .percent:after {content: '%';}

.chart-style-03 .chart-circle {margin: 0 0 20px 0;}
.chart-style-03 .chart-03 {position: relative;display: inline-block;width: 140px;height: 140px;text-align: center;}
.chart-style-03 .chart-03 canvas {position: absolute;top: 0;left: 0;}
.chart-style-03 .percent {display: inline-block;line-height: 65px;z-index: 2;border-radius: 100px;width: 65px;height: 65px;background-color: #fff;position: absolute;top: 50%;transform: translate(-50%, -50%);-webkit-transform: translate(-50%, -50%);-moz-transform: translate(-50%, -50%);}
.chart-style-03 .percent:after {content: '%';}

/* Error Page */

@-moz-keyframes rocket-movement {
  100% {
    -moz-transform: translate(1200px, -600px);
  }
}
@-webkit-keyframes rocket-movement {
  100% {
    -webkit-transform: translate(1200px, -600px);
  }
}
@keyframes rocket-movement {
  100% {
    transform: translate(1200px, -600px);
  }
}
@-moz-keyframes spin-earth {
  100% {
    -moz-transform: rotate(-360deg);
    transition: transform 20s;
  }
}
@-webkit-keyframes spin-earth {
  100% {
    -webkit-transform: rotate(-360deg);
    transition: transform 20s;
  }
}
@keyframes spin-earth {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    transition: transform 20s;
  }
}

@-moz-keyframes move-astronaut {
  100% {
    -moz-transform: translate(-160px, -160px);
  }
}
@-webkit-keyframes move-astronaut {
  100% {
    -webkit-transform: translate(-160px, -160px);
  }
}
@keyframes move-astronaut {
  100% {
    -webkit-transform: translate(-160px, -160px);
    transform: translate(-160px, -160px);
  }
}
@-moz-keyframes rotate-astronaut {
  100% {
    -moz-transform: rotate(-720deg);
  }
}
@-webkit-keyframes rotate-astronaut {
  100% {
    -webkit-transform: rotate(-720deg);
  }
}
@keyframes rotate-astronaut {
  100% {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

@-moz-keyframes glow-star {
  40% {
    -moz-opacity: 0.3;
  }
  90%,
  100% {
    -moz-opacity: 1;
    -moz-transform: scale(1.2);
  }
}
@-webkit-keyframes glow-star {
  40% {
    -webkit-opacity: 0.3;
  }
  90%,
  100% {
    -webkit-opacity: 1;
    -webkit-transform: scale(1.2);
  }
}
@keyframes glow-star {
  40% {
    -webkit-opacity: 0.3;
    opacity: 0.3;
  }
  90%,
  100% {
    -webkit-opacity: 1;
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    border-radius: 999999px;
  }
}

.spin-earth-on-hover {
  transition: ease 200s !important;
  transform: rotate(-3600deg) !important;
}

.bg-purple {
  background: url(http://salehriaz.com/404Page/img/bg_purple.png);
  background-repeat: repeat-x;
  background-size: cover;
  background-position: left top;
  height: 100%;
  overflow: hidden;
}

.objects img {
  z-index: 90;
  pointer-events: none;
}

.object_rocket {
  z-index: 95;
  position: absolute;
  transform: translateX(-50px);
  top: 75%;
  pointer-events: none;
  animation: rocket-movement 200s linear infinite both running;
}

.object_earth {
  position: absolute;
  top: 20%;
  left: 15%;
  z-index: 90;
}

.object_moon {
  position: absolute;
  top: 12%;
  left: 25%;
}

.object_astronaut {
  animation: rotate-astronaut 200s infinite linear both alternate;
}

.box_astronaut {
  z-index: 110 !important;
  position: absolute;
  top: 60%;
  right: 20%;
  will-change: transform;
  animation: move-astronaut 50s infinite linear both alternate;
}

.image-404 {
  position: relative;
  z-index: 100;
  pointer-events: none;
  width: 50%;
  margin: 0 auto;
  display: block;
}

.stars {
  height: 100vh;
}

.glowing_stars .star {
  position: absolute;
  border-radius: 100%;
  background-color: #fff;
  width: 3px;
  height: 3px;
  opacity: 0.3;
  will-change: opacity;
}

.glowing_stars .star:nth-child(1) {
  top: 80%;
  left: 25%;
  animation: glow-star 2s infinite ease-in-out alternate 1s;
}
.glowing_stars .star:nth-child(2) {
  top: 20%;
  left: 40%;
  animation: glow-star 2s infinite ease-in-out alternate 3s;
}
.glowing_stars .star:nth-child(3) {
  top: 25%;
  left: 25%;
  animation: glow-star 2s infinite ease-in-out alternate 5s;
}
.glowing_stars .star:nth-child(4) {
  top: 75%;
  left: 80%;
  animation: glow-star 2s infinite ease-in-out alternate 7s;
}
.glowing_stars .star:nth-child(5) {
  top: 90%;
  left: 50%;
  animation: glow-star 2s infinite ease-in-out alternate 9s;
}


/* Coming Soon */

.comingsoon-bg{background-position: center;background-repeat: no-repeat;background-size: cover;margin: 0;position: relative;height: 100vh;}
.content-wrap {margin: 20px auto 40px auto;max-width: 100%;padding: 0 15px;text-align: center;}
.logo-box img {max-width: 110px;}
.cta-box h1 {color: #fff;font-size: 58px;font-weight: 800;text-shadow: 0 5px 10px rgba(0, 0, 0, 0.7);font-family: "Raleway", sans-serif;}
.cta-box p {color: #fff;font-size: 20px;margin-top: 20px;font-family: "Open Sans", sans-serif;line-height: 35px;}

/* Custom Images */

.img-center{position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 10;background-repeat: no-repeat;background-size: cover;background-position: center center;}
.responsive-screen {margin-top: 50px;}

/* Demos */

.product{margin:0 0 30px 0;}
.product-wrap{position:relative;overflow:hidden;}
.product-img img{width:100%;}
.product-wrap .product-caption{width:100%;position:absolute;z-index:2;height:100%;opacity:0;top:0;background-color:rgba(0,0,0,0.9);-webkit-transition:opacity 0.4s;transition:opacity 0.4s;}
.product-wrap:hover .product-caption{opacity:1;}
.product-description{width:100%;height:100%;}
.product-description-wrap{position:absolute;top:50%;left:0;width:100%;margin-top:-50px;}
.product-description .product-title, .product-description .product-subtitle{position:relative;left:0;color:#fff;width:100%;opacity:0;visibility:hidden;}
.product-description .product-title{top:0;-webkit-transition:0.3s;-moz-transition:0.3s;-o-transition:0.3s;transition:0.3s;}
.product-description .product-title a i{color:#fff;}
.product-description .product-subtitle{top:0;-webkit-transition:0.4s;-moz-transition:0.4s;-o-transition:0.4s;transition:0.4s;}
.product-wrap:hover .product-description .product-title{opacity:1;visibility:visible;top:30px;}
.product-wrap:hover .product-description .product-subtitle{opacity:1;visibility:visible;top:25px;}
.product .product-detail{margin-top:30px;}


.demo-box {margin-bottom:25px;text-align:center;}
.demo-box a{position:relative;box-shadow:0px 0px 51px 0px rgba(0, 0, 0, 0.08), 0px 6px 18px 0px rgba(0, 0, 0, 0.05);transition:0.35s cubic-bezier(0.4, 0, 0.2, 1);transform:translateY(0px);}
.demo-box a:hover{box-shadow:0px 0px 114px 0px rgba(0, 0, 0, 0.08), 0px 30px 25px 0px rgba(0, 0, 0, 0.05);transform:translateY(-10px);}
.demo-box img{box-shadow:0 10px 30px 0 rgba(31,35,37,.1);transition:all .35s cubic-bezier(0.4,0,.2,1);}
.single-img{display:inline-block;vertical-align:top;max-width:100%;}
.demo-box h3{text-transform:uppercase;font-size:16px;text-align:center;letter-spacing:0.15em;color:#fff;}
circle-progress {
  width: 140px;
  height: 100px;
}


.filter-white.cbp-l-filters-alignCenter .cbp-filter-item.cbp-filter-item-active{color:#4facfe;border-bottom: 1px solid #4facfe;}
.filter-white.cbp-l-filters-alignCenter .cbp-filter-item{color:#fff;margin: 0 10px;}
.filter-white.cbp-l-filters-alignCenter .cbp-filter-item:hover {color: #888;}
.portfolio-layout-slider .item-img{margin: 0 15px;}
.portfolio-layout-slider .item-img img{box-shadow: 0 10px 30px 0 rgba(31,35,37,.1);}
